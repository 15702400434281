angular.module('uetp')
    .component('addEisMethod', {
        templateUrl: 'app/components/proceduresEIS/eisAddMethod/addEisMethod.html',
        bindings: {
            procedureData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', 'proceduresSrv','httpSrv', 'alertSrv', 'customerSrv', 'noticeSrv','authSrv', 'dictionarySrv', 'propertiesService',
            function ($scope, $rootScope, $state, proceduresSrv, httpSrv, alertSrv, customerSrv, noticeSrv, authSrv, dictionarySrv, propertiesService) {

                this.$onInit = function () {
                    $scope.model = {};
                    $scope.model.purchaseData = {};
                    $scope.form = {addMethodeForm: {}};
                    $scope.submitted = false;
                    $scope.filterElement = {};
                    $scope.model.purchaseData.duplicateEisCode = true;
                    $scope.method = {};
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.uetpCodeFullList = dictionarySrv.getStaticDictByType('purchaseMethod').filter(m => !m.id.contains('smb') &&
                        !['offerplacement','saleauction','openpublicoffer','simplecontest'].includes(m.id)).sort(function(a, b) {
                        if(a['name'] > b['name']) return 1;
                        else if(a['name'] < b['name']) return -1;
                        else return 0;
                    });
                    $scope.goBack = goBackFn;
                    $scope.saveOrUpdateMethod = saveOrUpdateMethodFn;
                };
                $scope.requestToElastic = requestToElasticFn;

                $scope.changeMethod = function() {
                    $scope.visibilityButton = true;
                    $scope.protocolList = [];
                    $scope.rebiddingProtocolList = [];
                    $scope.additionalProtocolList = [];
                    $scope.uetpCodeList = getActualUetpCodeList();
                    if($scope.method && $scope.method.uetp) {
                        $scope.protocolList = protocolListFn($scope.method);
                        $scope.rebiddingProtocolList = rebiddingProtocolListFn($scope.method);
                        $scope.additionalProtocolList = additionalProtocolListFn($scope.method);
                        $scope.prt = getProtocolList();
                    }

                }

                $scope.changeEisName = function (selectObj, protocol) {
                    let selected = selectObj.$select.selected;
                    if (selected) {
                        protocol.eisCode = selected.code;
                    } else {
                        protocol.eisCode = null;
                    }
                }

                function goBackFn() {
                    history.back();
                }

                function getActualUetpCodeList() {
                    return $scope.uetpCodeFullList.filter(c =>!['open2stagecontest', 'limitedcontestqualification'].includes(c.id)).map((el) => {
                        return {
                            code: el.id,
                            name: el.name
                        }
                    });
                }

                function getProtocolList() {
                    let prt = [];
                    return $scope.model.purchaseMethod.filter(c => !prt.includes(c.protocolCode)).map((el) => {
                        return {
                            code: el.protocolCode,
                            name: el.protocolName
                        }
                    });
                }

                function requestToElasticFn(planNumber, positionPlanNumber) {
                    $scope.model.purchaseData.visible = false;
                    $scope.model.purchaseData.duplicateEisCode = false;
                    $scope.visibilityButton = false;
                    $scope.method.uetp = "";
                    $scope.successSave = false;
                    $scope.protocolList = [];
                    $scope.alertOnEmptyResponse = "";
                    $scope.currentPlanNumber = planNumber;
                    $scope.currentPositionPlanNumber = positionPlanNumber;
                    $scope.alertOnDuplicate = "";
                    let param = {
                        searchByField: {
                            id: "" + planNumber + positionPlanNumber
                        }
                    };

                    proceduresSrv.searchPurchases($scope, param).then((response) => {
                        if(response && response.data && response.data.success && response.data.items && response.data.items.length > 0) {
                            $scope.model.purchase = response.data.items[0];
                            initMethodList();
                            provideDateFromPurchase();
                            getPurchaseMethode($scope.model.purchase);
                        } else {
                            $scope.alertOnEmptyResponse = alertSrv.getErrorMsg('Не удалось найти указанную позицию');
                        }
                    });


                }

                 function checkEisDuplicate(data) {
                    if (data.length === 0) $scope.model.purchaseData.duplicateEisCode = false;

                    let duplicateEisCode = data.filter( t => $scope.model.purchaseData.method_code.includes(t.eisCode));
                    if (duplicateEisCode.length > 0) {
                        $scope.model.purchaseData.duplicateEisCode = true;
                        $scope.visibilityButton = true;
                        $scope.method.uetp = {name: duplicateEisCode[0].uetpName, code: duplicateEisCode[0].uetpName};
                        $scope.alertOnDuplicate = alertSrv.getErrorMsg('Данный способ уже добавлен');
                    }
                }

                function getPurchaseMethode(data) {
                    propertiesService.getProperty("otherProcedures.url").then((host) => {
                        httpSrv.requestXHR($scope,{
                             method: 'POST',
                             url: host + '/rest/other-purchases/proc/getProtocols/' + data.method_code
                        }).then((response) => {
                            if (response.data.success && response.data.result) {
                                $scope.model.purchaseMethod = response.data.result;
                            }
                        });
                    });
                }

                //заполняем поля
                function provideDateFromPurchase() {
                    $scope.model.purchaseData.visible = true;
                    $scope.model.purchaseData.versionPlan = $scope.model.purchase.versionPlan;
                    $scope.model.purchaseData.loadDate = $scope.model.purchase.loadDate;
                    $scope.model.purchaseData.positionPlanGuid = $scope.model.purchase.positionPlanGuid;
                    $scope.model.purchaseData.placer_name = $scope.model.purchase.placer.placer_name;
                    $scope.model.purchaseData.placer_inn = $scope.model.purchase.placer.placer_inn;
                    $scope.model.purchaseData.object_info = $scope.model.purchase.object_info;
                    $scope.model.purchaseData.fullprice = $scope.model.purchase.fullprice;
                    $scope.model.purchaseData.method_code = $scope.model.purchase.method_code;
                    $scope.model.purchaseData.method_name = $scope.model.purchase.method_name;
                }

                function initMethodList() {
                    noticeSrv.getCustomerByInn($scope.model.purchase.customers[0].inn).then((response) => {
                        if(response && response.data && response.data.success) {
                            $scope.model.customer = response.data.result;

                            initMethodListFn($scope.model.customer);
                        }
                    });
                }

                function initMethodListFn(customer) {
                    customerSrv.loadMethodList(customer.id).then(function successCallback(response) {
                        if (response.data.success) {
                            checkEisDuplicate(response.data.result);
                            customerSrv.setUetpCodes(response.data.result ? response.data.result.map(m => m.uetpCode) : []);
                            $scope.uetpCodeList = getActualUetpCodeList();
                        } else {
                            customerSrv.setUetpCodes([]);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                function protocolListFn(purchaseMethod) {
                    let result = [];
                    if(purchaseMethod) {
                        if(['opencontest', 'limitedcontest','requestquotations', 'limitedrequestquotations'].includes(purchaseMethod.uetp.code))
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения и оценки заявок", uetpCode: "PART1", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                            ]
                        else if(['openauction', 'limitedauction'].includes(purchaseMethod.uetp.code))
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения заявок", uetpCode: "PARTS", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол проведения аукциона", uetpCode: "REZ", uetpTemplate: 'other'})
                            ]
                        else if(['requestproposals', 'limitedrequestproposals'].includes(purchaseMethod.uetp.code))
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол запроса предложений", uetpCode: "PART1", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия окончательных предложений", uetpCode: "OPEN", uetpStage: 'stage2', uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения и оценки окончательных предложений", uetpCode: "PART1", uetpStage: 'stage2', uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                            ]
                        else if('open2stagecontest'===purchaseMethod.uetp.code)
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия первоначальных заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения первоначальных заявок", uetpCode: "PART1", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия окончательных заявок", uetpCode: "OPEN", uetpStage: 'stage2', uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения и оценки окончательных заявок", uetpCode: "PART1", uetpStage: 'stage2', uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                            ]
                        else if('prequalification' === purchaseMethod.uetp.code)
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения заявок", uetpCode: "PARTS", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                            ]
                        else if('unlimitedprequalification' === purchaseMethod.uetp.code)
                            result = [getStored(purchaseMethod, {uetpName: "Уведомление об итогах рассмотрения заявок", uetpCode: "PART1", uetpTemplate: 'other'})]
                        else if('limitedcontestqualification' === purchaseMethod.uetp.code)
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения заявок", uetpCode: "PARTS", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                            ]
                    }

                    function getStored(purchaseMethod, defaultResult) {
                        if(purchaseMethod.protocols && purchaseMethod.protocols.length>0){
                            let protocol = defaultResult.uetpStage !== null && angular.isDefined(defaultResult.uetpStage) ?
                                purchaseMethod.protocols.filter(p => p.uetpCode === defaultResult.uetpCode && p.uetpStage === defaultResult.uetpStage) :
                                purchaseMethod.protocols.filter(p => p.uetpCode === defaultResult.uetpCode);
                            if(protocol && protocol.length > 0)
                                return protocol[0];
                        }
                        return defaultResult;
                    }

                    return result;
                }

                $scope.getPlanInfo = () => {
                    return `Данные по позиции ${$scope.currentPlanNumber}-${$scope.currentPositionPlanNumber}`;
                }

                function rebiddingProtocolListFn(purchaseMethod) {
                    let result = [];
                    if(purchaseMethod) {
                        if(['opencontest', 'limitedcontest','requestquotations', 'limitedrequestquotations','requestproposals','limitedrequestproposals'].includes(purchaseMethod.uetp.code))
                            return [
                                getStored(purchaseMethod,{uetpName: "Уведомление о переторжке в режиме реального времени", uetpCode: "REBIDDING_NOTICE", uetpTemplate: 'other'}),
                                getStored(purchaseMethod,{uetpName: "Уведомление о переторжке в заочной форме", uetpCode: "REBIDDING_NOTICE", uetpTemplate: 'other'}),
                                getStored(purchaseMethod,{uetpName: "Протокол вскрытия переторжки в заочной форме", uetpCode: "REBIDDING_OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod,{uetpName: "Протокол проведения переторжки в режиме реального времени", uetpCode: "REBIDDING_OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod,{uetpName: "Протокол рассмотрения и оценки заявок с учетом переторжек", uetpCode: "REBIDDING_PART1", uetpTemplate: 'other'})
                            ]
                    }

                    function getStored(purchaseMethod, defaultResult) {
                        if(purchaseMethod.protocols && purchaseMethod.protocols.length > 0){
                            let protocol = purchaseMethod.protocols.filter(p => p.uetpCode === defaultResult.uetpCode && p.uetpName === defaultResult.uetpName);
                            if(protocol && protocol.length > 0) {
                                return protocol[0];
                            }
                        }
                        return defaultResult;
                    }

                    return result;
                }
                function additionalProtocolListFn(purchaseMethod) {
                    if(purchaseMethod) {
                        if(!['prequalification', 'unlimitedprequalification'].includes(purchaseMethod.uetp.code))
                            return [
                                getStored(purchaseMethod,{uetpName: "Протокол изменений условий договора", uetpCode: "CHANGE_CONTRACT_ADDITIONAL", uetpTemplate: 'other'})
                            ]
                    }

                    function getStored(purchaseMethod, defaultResult) {
                        if(purchaseMethod.protocols && purchaseMethod.protocols.length > 0){
                            let protocol = purchaseMethod.protocols.filter(p => p.uetpCode === defaultResult.uetpCode && p.uetpName === defaultResult.uetpName);
                            if(protocol && protocol.length > 0) {
                                return protocol[0];
                            }
                        }
                        return defaultResult;
                    }

                    return [];
                }

                $scope.hasAdditionalProtocols = function(uetp){
                    return uetp && !['prequalification', 'unlimitedprequalification'].includes(uetp.code);
                }

                function saveOrUpdateMethodFn() {

                    const dataObj = angular.copy($scope.method);

                    dataObj.uetpCode = dataObj.uetp.code;
                    dataObj.uetpName = dataObj.uetp.name;
                    delete dataObj.uetp;
                    dataObj.eisCode = $scope.model.purchaseData.method_code;
                    dataObj.eisName = $scope.model.purchaseData.method_name;
                    dataObj.customer = $scope.model.customer;
                    dataObj.protocols = getProtocols();

                    customerSrv.createOrUpdateMethod(dataObj).then(function successCallback(response) {
                        if(!response.data.success) {
                            $scope.alertObjProfileMethodEdit = alertSrv.getErrorMsg("При сохранении способа закупки произошла ошибка. Попробуйте выполнить запрос позднее или обратитесь в техническую поддержку.");
                        } else {
                            const successSaveMsg = 'Способ закупки добавлен';
                            $scope.method.id = response.data.result;
                            $scope.successSave = true;
                            $scope.alertObjProfileMethodEdit = alertSrv.getSuccessMsg(successSaveMsg);
                        }
                    }, function errorCallback(response) {
                        $scope.alertObjProfileMethodEdit = alertSrv.getAlertObj(response);
                        console.log(response);
                    });

                    function getProtocols() {
                        let result = [];
                        let list = $scope.protocolList;
                        if($scope.rebiddingProtocolList) list = list.concat($scope.rebiddingProtocolList);
                        if($scope.additionalProtocolList) list = list.concat($scope.additionalProtocolList);
                        angular.forEach(list, function(el){
                            if(el.eisCode && el.eisName)
                                if(el.uetpStage)
                                    result.push({id: el.id, uetpCode: el.uetpCode, uetpName: el.uetpName, eisCode: el.eisCode, eisName: el.eisName, uetpTemplate: el.uetpTemplate, uetpStage: el.uetpStage});
                                else
                                    result.push({id: el.id, uetpCode: el.uetpCode, uetpName: el.uetpName, eisCode: el.eisCode, eisName: el.eisName, uetpTemplate: el.uetpTemplate});
                        });
                        return result;
                    }
                }
            }]
    });
