//Raw scripts (ES5)
import './cripto/cadesplugin_api.script.js';
import './utils/clock.script.js';
import './components/freshdoc/freshdoc.script.js';
import './utils/socket.io.script.js';
import './utils/globalImgError.script.js';

//npm, git
import 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-resource';
import 'ui-select';
import 'angular-ui-bootstrap';
import 'textangularjs/dist/textAngular-sanitize.min.js';
import 'textangularjs/dist/textAngular.css';
import 'textangularjs';
import 'angular-ui-router';
import 'ng-dialog';
import 'ng-dialog/css/ngDialog.min.css';
import 'ng-dialog/css/ngDialog-theme-default.min.css';
import 'angular-jwt';
import 'ng-mask';
import 'angular-filter';
import 'angular-loading-bar';
import 'angular-loading-bar/src/loading-bar.css';
import 'angularjs-dropdown-multiselect';
import 'angularjs-slider';
import 'angular-chart.js';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'angularjs-slider/dist/rzslider.min.css';
import 'angular-touch';

//Local vendor
import '../vendor/angular-locale_ru-ru.js';
import '../vendor/angular-toggle-switch.js';
import '../vendor/fontawesome/styles/fontawesome.min.css';
import '../vendor/select.css';

//Utils
import './utils/infinityScroll.js';
import './utils/angular-upload.js';
import './cripto/crypt.js';

import './app.js';

//enums
import './enums/attachTypeCatalog.enum.js';

import './config/filters.js';
import './config/states.js';
import './services/storage.service.js';
import './services/visibleFields.service.js';
import './services/http.service.js';
import './services/needConfirmState.service.js';
import './services/dictionary.service.js';
import './services/timeout.service.js';
import './services/dialog.service.js';
import './services/desktop.service.js';
import './services/auth.service.js';
import './services/cryptography.js';
import './services/data.service.js';
import './services/onlineCheck.service.js';
import './services/propertiesService.js';
import './services/title.service.js';
import './services/permission.service.js';
import './factory/sessionInterceptorFactory.js';
import './factory/scInterceptorFactory.js';
import './factory/requestErrorInterceptorFactory.js';
import './factory/fileBlockScreenFactory.js';
import './components/pagination/pagination.js';
import './components/pagination/pagination.service.js';
import './components/header/headerMsg/headerMsg.js';
import './components/lk/notification/lknotification.js';
import './components/lk/notification/lknotification.service.js';
import './components/header/cabinet/cabinet.js';
import './components/header/headerToolbar.js';
import './components/footer/footer.js';
import './components/home/home.js';
import './components/login/login.js';
import './components/login/webmarketLogin.js';
import './components/filter/filter.js';
import './components/filter/filter.service.js';
import './components/profile/profile.service.js';
import './components/profile/profile.js';
import './components/profile/pwdChange/pwdChange.js';
import './components/profile/versionInfo/versionInfo.js';
import './components/profile/requisites/requisites.js';
import './components/profile/userInfo/userInfo.js';
import './components/profile/agents/agentList.js';
import './components/profile/agents/agentEdit/agentEditor.js';
import './components/profile/customers/customersProfile.js';
import './components/profile/actions/actionsProfile.js';
import './components/profile/personalAccount/personalAccount.js';
import './components/profile/accreditation/accreditationList.js';
import './components/profile/accreditation/accreditationEdit/accreditationEditor.js';
import './components/profile/accreditation/accelerationAccreditation/accelerationAccreditation.js';
import './components/profile/filiation/filiationList.js';
import './components/profile/filiation/filiationEdit/filiationEditor.js';
import './components/bgParticipants/bgParticipants.service.js';
import './components/bgParticipants/bgParticipants.js';
import './components/bgParticipants/order/orderList.js';
import './components/bgParticipants/limit/limitList.js';
import './components/rfGuaranteeApp/rfGuaranteeApp.js';
import './components/rfGuaranteeApp/rfGuaranteeApp.service.js';
import './components/subscription/subscription.js';
import './components/subscription/subscription.service.js';
import './components/tariffsServices/tariffs/tariffsMenu.js';
import './components/lk/lk.js';
import './components/lk/lkinfo/lkinfo.js';
import './components/news/newsCreate/newsCreate.js';
import './components/news/newsCreate/newsCreate.service.js';
import './components/versionInfos/versionCreate/versionCreate.js';
import './components/versionInfos/versionCreate/versionCreate.service.js';
import './components/news/news.js';
import './components/news/homeNews/homeNews.js';
import './components/news/homeNews/homeNewsPpk.js';
import './components/news/homeNews/topNewsPpk.js';
import './components/news/homeNews/homeNews.service.js';
import './components/news/newsBlock/newsBlock.js';
import './components/help/questionAnswer/questionAnswerCreate.js';
import './components/help/questionAnswer/questionAnswerHomeView.js';
import './components/help/questionAnswer/questionAnswer.service.js';
import './components/help/documentSamples/documentSamplesCreate.js';
import './components/help/documentSamples/documentSamples.service.js';
import './components/newsletters/newsletterCreate/newsletterCreate.js';
import './components/newsletters/newsletterCreate/newsletterCreate.service.js';
import './components/notice/notice.js';
import './components/notice/notice.service.js';
import './components/notice/documentation/documentation.js';
import './components/notice/extendNoticeField/extendNoticeField.js';
import './components/notice/procedure/procedure.js';
import './components/notice/lot/lot.js';
import './components/notice/lots/lots.js';
import './components/notice/nomenclature/nomenclature.js';
import './components/registration/registration.js';
import './components/fas/fasArchive.js';
import './components/fas/fasListArchive/fasListArchive.js';
import './components/customer/registration/customerRegistration.js';
import './components/customer/registration/customerRegistrationOpen.js';
import './components/customer/registration/customerRegistration.service.js';
import './components/customer/customer.service.js';
import './components/customer/customer.js';
import './components/customer/customerRequisites/customerRequisites.js';
import './components/customer/customerEisIntegration/customerEisIntegration.js';
import './components/customer/customerEisIntegration/methodEdit/methodEditor.js';
import './components/customer/customerEisIntegration/customerBranches.js';
import './components/customer/customerEisIntegration/branches/branchEditor.js';
import './components/participant/participant.service.js';
import './components/participant/participant.js';
import './components/participant/participantRequisites/participantRequisites.js';
import './components/participant/participantAgents/participantAgents.js';
import './components/participant/participantAccreditation/accreditationList.js';
import './components/participant/participantAgents/participantAgentView/participantAgentView.js';
import './components/participationInPurchases/participationInPurchases.js';
import './components/bgAgent/consent/bgAgents.js';
import './components/bgAgent/bgAgent/bgAgent.js';
import './components/bgAgent/bgAgent/bgAgent.service.js';
import './components/bgAgent/bgAgent/reqOrganizator/reqOrganizator.js';
import './components/bgAgent/bgAgent/agentOrg/agentList.js';
import './components/bgAgent/bgAgent/agentOrg/agentEdit/agentEditor.js';
import './components/organizator/organizator.service.js';
import './components/organizator/organizator.js';
import './components/organizator/reqOrganizator/reqOrganizator.js';
import './components/organizator/customersOrg/customersOrg.js';
import './components/organizator/agentOrg/agentList.js';
import './components/organizator/agentOrg/agentEdit/agentEditor.js';
import './components/paymentOrder/paymentOrder.service.js';
import './components/paymentOrder/paymentOrder.js';
import './components/paymentOrder/confirmPayment/confirmPayment.js';
import './components/paymentOrder/table/paymentOrdersTable.js';
import './components/coveringLetter/coveringLetter.js';
import './components/coveringLetter/coveringLetter.service.js';
import './components/bankGuaranteeApp/bankGuaranteeApp.js';
import './components/bankGuaranteeApp/formCreator/bankGuaranteeFormCreator.js';
import './components/bankGuaranteeApp/bankGuaranteeApp.service.js';
import './components/bankGuaranteeAppLoko/bankGuaranteeAppLoko.js';
import './components/bankGuaranteeAppLoko/bankGuaranteeAppLoko.service.js';
import './components/bankGuaranteeAppVTB/bankGuaranteeAppVTB.js';
import './components/bankGuaranteeAppVTB/bankGuaranteeAppVTB.service.js';
import './components/bankGuarantees/bankGuarantees.js';
import './components/bankGuaranteeVTBChat/bankGuaranteeVTBChat.js';
import './components/bankGuaranteeLokoChat/bankGuaranteeLokoChat.js';
import './components/bankGuaranteeChat/bankGuaranteeChat.js';
import './components/bankGuaranteeCalc/bankGuaranteeCalc.js';
import './components/bank/bank.js';
import './components/bankRaiffeisen/bankRaiffeisen.js';
import './components/bankVTBLanding/bankVTBLanding.js';
import './components/bankLoko/bankLoko.js';
import './components/explanation/request/reqExplanation.js';
import './components/explanation/answer/answerExplanation.js';
import './components/explanation/email/answerExplanationEmail.js';
import './components/explanation/answer/answerReady/answerReady.js';
import './components/explanation/explanation.service.js';
import './components/explanationOrg/request/reqExplanationOrg.js';
import './components/explanationOrg/answer/answerExplanationOrg.js';
import './components/explanationOrg/explanationOrg.service.js';
import './components/factoring/factoring.js';
import './components/proceduresEIS/procedures.js';
import './components/proceduresEIS/eisAddMethod/addEisMethod.js';
import './components/proceduresEIS/eisIntegrationProcEdit/eisIntegrationProcEdit.js';
import './components/procedures/procedures.js';
import './components/procedures/reject/informationAboutRejectProcedure.js';
import './components/procedures/cancelLots/informationAboutCancelLots.js';
import './components/procedures/protocol/additional/additionalProtocol.js';
import './components/procedures/noticePostponement/noticePostponementProcedure.js';
import './components/procedures/returnClaims/returnClaims.js';
import './components/procedures/protocol/notificationProtocol.js';
import './components/procedures/procedures.service.js';
import './components/procedures/reqExplanations/reqExplanations.js';
import './components/procedures/explanations/explanations.js';
import './components/procedures/protocol/protocol.js';
import './components/procedures/auction/auction.js';
import './components/procedures/rebiddings/rebiddings.js';
import './components/procedures/reqExplanationsOrg/reqExplanationsOrg.js';
import './components/procedures/contract/contract.js';
import './components/procedures/contract/contract.service.js';
import './components/procedures/claims/claims.js';
import './components/procedures/statistics/statistics.js';
import './components/procedures/priceOffers/priceOffers.js';
import './components/bgBank/bgBank/bgBank.js';
import './components/bgBank/bgBank/bgBank.service.js';
import './components/bgBank/bgBank/bgBankReq/bgBankReq.js';
import './components/bgBank/bgBank/bgBankAgents/bgBankAgents.js';
import './components/bgBank/bgBank/bgBankAgents/bgBankAgentEditor/bgBankAgentEditor.js';
import './components/purchasePlanUpdate/purchasePlanUpdate.js';
import './components/purchasePlanUpdate/purchasePlanUpdate.service.js';
import './controllers/restorePassword/restoredChangePwd.controller.js';
import './controllers/tables/tables.service.js';
import './controllers/tables/tables.controller.js';
import './components/participate/participate.js';
import './components/participate/participate.service.js';
import './components/startPageSearch/startPageSearch.js';
import './components/startPageSearch/startPageSearch.service.js';
import './components/contacts/contacts.js';
import './components/servicesPage/servicesPage.js';
import './components/about/about.js';
import './components/bgAgent/becomeBgAgent/becomeBgAgent.js';
import './components/bgAgent/bgAdvertising/bgAdvertising.js';
import './components/becomeCustomer/becomeCustomer.js';
import './components/becomeSupplier/becomeSupplier.js';
import './components/systemMessage/systemMessage.js';
import './components/systemMessage/systemMessage.service.js';
import './components/systemMessage/counter.js';
import './components/systemMessage/explreqs.js';
import './components/tariffsServices/tariffs/tariffs.service.js';
import './components/tariffsServices/tariffs/tariffs.js';
import './components/invoice/table/invoiceTable.js';
import './components/completedWorks/table/completedWorksTable.js';
import './components/completedWorks/completedWorks.service.js';
import './components/completedWorks/completedWork.js';
import './components/statement/table/statementTable.js';
import './components/reports/reports.controller.js';
import './components/reports/analyticReports.controller.js';
import './components/reports/reportsLokoBank/reportsLokoBank.controller.js';
import './components/feedbackForm/feedbackForm.js';
import './components/freshdoc/freshdoc.controller.js';
import './components/rekl/rekl.js';
import './components/privacy/privacy.js';
import './components/mailingLists/mailingLists.js';
import './components/invoice/invoice.js';
import './components/invoice/invoice.service.js';
import './components/statement/statement.service.js';
import './components/invoiceRequestForm/invoiceRequestForm.js';
import './components/invoiceRequestForm/invoiceRequestForm.service.js';
import './controllers/uc/uc.service.js';
import './controllers/uc/uc.controller.js';
import './controllers/tables/documentsList.const.js';
import './controllers/tables/presentationList.const.js';
import './controllers/tables/videoList.const.js';
import './controllers/tables/statPrices.const.js';
import './components/protocol/protocol.js';
import './components/protocol/protocol.service.js';
import './components/positionsImg/positionsImg.js';
import './components/preview/preview.js';
import './components/widgets/tassBusiness/tassBusiness.service.js';
import './components/widgets/tassBusiness/companyValidate/view/baseView.js';
import './components/widgets/tassBusiness/companyValidate/view/blockedAccounts/blockedAccounts.js';
import './components/widgets/tassBusiness/companyValidate/view/contracts/contracts.js';
import './components/widgets/tassBusiness/companyValidate/view/customers/customers.js';
import './components/widgets/tassBusiness/companyValidate/view/providers/providers.js';
import './components/widgets/tassBusiness/companyValidate/view/reporting/reporting.js';
import './components/widgets/tassBusiness/companyValidate/view/verification/verification.js';
import './components/widgets/tassBusiness/companyValidate/view/verification/elementVerification/elementVerification.js';
import './components/widgets/tassBusiness/companyValidate/view/taxes/taxes.js';
import './components/widgets/tassBusiness/companyValidate/view/finReport/finReport.js';
import './components/placeholderCards/placeholderCards.js';
import './components/tariffsServices/securingZmo/securingZmo.js';
import './components/tariffsServices/accelAccred/accelAccred.js';
import './components/positionElement/positionElement.js';
import './components/offer/offer.js';
import './components/offer/offer.service.js';
import './components/position/position.js';
import './components/position/position.service.js';
import './components/regionSelector/regionSelector.js';
import './components/regionSelector/list.service.js';
import './components/cart/cart.js';
import './components/cart/cart.service.js';
import './components/cart/cartCounter.js';
import './components/order/order.js';
import './components/order/order.service.js';
import './components/docHistory/docHistory.js';
import './components/docHistory/docHistory.service.js';
import './config/systemMessages.const.js';
import './filter/selectFilter.js';
import './filter/truncate.js';
import './filter/sumInWords.js';
import './filter/bytes.js';
import './filter/previewText.js';
import './filter/stripHTML.js';
import './directives/linksActivating.js';
import './directives/lineBreaks.js';
import './directives/currency.js';
import './directives/chromeautomplete.js';
import './directives/inputFormat.js';
import './directives/denySpaces.js';
import './directives/escapeChar.js';
import './directives/doubleTap.js';
import './directives/extraList.js';
import './directives/noDirty.js';
import './directives/mskDate.js';
import './directives/maxTime.js';
import './directives/maxLength.js';
import './directives/formChange.js';
import './directives/onlyDate.js';
import './directives/onlyRequired.js';
import './directives/normalDate.js';
import './directives/validation.js';
import './directives/searchCounter/scDirective.js';
import './directives/currency/currency.js';
import './directives/subscriptionButtons.js';
import './directives/subscriptionNews.js';
import './directives/dragdropPanel/dragdropPanel.js';
import './directives/inputfiles/inputfiles.js';
import './directives/alertPanel/alertPanel.js';
import './directives/actionsBtn/actionsBtn.js';
import './directives/needConfirm/needConfirm.js';
import './directives/formatDate/formatDate.js';
import './directives/backBtn/backBtn.js';
import './directives/bunkGuarantees/bunkGuarantees.js';
import './directives/extendField/extendField.js';
import './directives/extendField/extendField.service.js';
import './directives/carouselPurchase/carouselPurchase.js';
import './directives/counter300Years/counter300Years.js';
import './directives/carousel.js';
import './directives/factoringcarousel.js';
import './directives/imageScreen.js';
import './directives/trimInput.js';
import './directives/loggerUI/loggerUI.js';
import './directives/metrikaAim/metrikaAim.js';
import './directives/subscribe/subscribe.js';
import './directives/subscribe/panel/subscribePanel.js';
import './directives/dragdropPanel/dragdropPaner.service.js';

//Styles
import './components/news/homeNews/homeNews.css';
import './components/news/news.css';
import './components/news/newsBlock/newsBlock.css';
import './components/widgets/tassBusiness/companyValidate/companyValidate.css';
import './components/bankGuaranteeApp/bankGuaranteeApp.css';
import './components/bankGuaranteeCalc/bankGuaranteeCalc.css';
import './components/bankGuarantees/bankGuarantees.css';
import './components/bankGuaranteeVTBChat/bankGuaranteeVTBChat.css';
import './components/bankGuaranteeLokoChat/bankGuaranteeLokoChat.css';
import './components/bankGuaranteeChat/bankGuaranteeChat.css';
import './components/bank/bank.css';
import './components/bankRaiffeisen/bankRaiffeisen.css';
import './components/bankLoko/bankLoko.css';
import './components/feedbackForm/feedbackForm.css';
import './components/procedures/contract/contract.css';
import './components/procedures/auction/auction.css';
import './components/positionElement/positionElement.css';
import './components/offer/offer.css';
import './components/order/order.css';
import './components/preview/preview.css';
import './components/profile/profile.css';
import './components/servicesPage/servicesPage.css';
import './components/completedWorks/completedWork.css';
import './components/lk/notification/lknotification.css';
import './components/becomeCustomer/becomeCustomer.css';
import './components/becomeSupplier/becomeSupplier.css';
import './components/bgAgent/becomeBgAgent/becomeBgAgent.css';
import './components/bgAgent/bgAdvertising/bgAdvertising.css';
import './components/fas/fasArchive.css';
import './directives/factoring/factoring.css';
import './components/factoring/factoring.css';
import './components/notice/procedure/procedure.css';

import './styles/carousel.css';
import './styles/material-switch.css';
import './styles/style.css'; //глобальные стили площадки
import './styles/maintenance.scss';
import './styles/angular-toggle-switch-bootstrap-3.css';

import './utils/sentry.es6';

import loadjs from 'loadjs';
import { loadTemplates } from './utils/angular-es.mjs';
import templates from '../templates.gen.es6';

(async ()=>{
	//Шаблоны ng-include
	loadTemplates(templates);

	//Метрика
	if (__envType === 'prod') {
		await loadjs('https://mc.yandex.ru/metrika/watch.js', {returnPromise: true});
		await import('./utils/metrika.es6');
	}

	if (['local', 'dev'].includes(__envType)) console.info(`Build success: ${__project}`);
})();
