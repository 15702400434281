/**
 * @author: sergeysi
 * Профиль пользователя. Список заказчиков организации
 */
angular.module('uetp')
    .component('customersProfile', {
        templateUrl: 'app/components/profile/customers/customersProfile.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'httpSrv', '$filter','dictionarySrv', 'tablesSrv', 'customerSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, httpSrv, $filter, dictionarySrv, tablesSrv, customerSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.modelViewAgent = {};
                    $scope.authData = authSrv;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.updateList = [];
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        page: 0,
                        itemsPerPage: 20,
                        orderBy: "inn",
                        orderAsc: true
                    };
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')[$scope.cabType()];
                    _initModelView();
                };
                $scope.customersLoad = customersLoadFn;
                $scope.changeSort = changeSortFn;
                $scope.setCustomerEisNds = setCustomerEisNdsFn;

                /**
                 * Инициализация модели в зависимости от ЛК
                 * @private
                 */
                function _initModelView() {
                    $scope.customersLoad()
                }

                /**
                 * Загрузка списка заказчиков
                 */
                function customersLoadFn(paginator) {
                    $scope.paginator = paginator || $scope.paginator;
                    profileSrv.customersLoad($scope.paginator, $scope.auth.orgId).then((response) => {
                        if (response.data.success) {
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                            $scope.customers = response.data.items;
                        }
                    }, (response) => {
                        console.log(response);
                    });
                }

                function setCustomerEisNdsFn(customer) {
                    if (!$scope.updateList) {
                        $scope.updateList = [];
                    }
                    $scope.updateList.push(customer.id);
                    customer.eisnds = !customer.eisnds;
                    customerSrv.updateCustomerEisNds(customer).then(() => {
                        $scope.updateList.splice($scope.updateList.indexOf(customer.id), 1);
                    });
                }

                $scope.isProcess = (customer) => {
                    return $scope.updateList.length>0 && $scope.updateList.indexOf(customer.id)!==-1;
                }

                function changeSortFn($event) {
                    const th = $event.currentTarget;
                    if (tablesSrv.changeSort($scope.paginator, th)) {
                        customersLoadFn($scope.paginator);
                    }
                }
            }]
    });