angular.module('uetp').component('mailingLists', {
	templateUrl: 'app/components/mailingLists/mailingLists.html',
	controller: [
		'$scope', '$filter',
		'constants', 'desktopSrv', 'httpSrv','organizatorSrv','dictionarySrv','authSrv','timeout','alertSrv', 'paginationSrv',
		function (
			$scope, $filter,
			constants, desktopSrv, httpSrv, organizatorSrv, dictionarySrv, authSrv, timeout,alertSrv, paginationSrv, //Списока адресатов
		) {
			$scope.model = {listsData: {},activeList:{participants:[]},editEntry:{}};
			$scope.showPanel = false;
			$scope.getPager = getPagerFn;
			$scope.refreshDict = refreshDictFn;
			this.$onInit = function () {
				$scope.paginator = {
					itemsPerPage: 20,
					page: 0
				};
				$scope.participantsPaginator = {
					itemsPerPage: 20,
					page: 0
				};
				$scope.organizer = organizatorSrv.getOrganizatorInfo();
				$scope.getDataMailingLists();
				paginationSrv.setPaginationStateConfig('pagination', 'mailinglists', $scope.paginator);
				paginationSrv.setPaginationStateConfig('participantsPaginator', 'orgParticipantList', $scope.participantsPaginator);
				_initDictionaryBase();
				$scope.participantInn = {search:''};
				$scope.model.activeList.participants = [];
			};

			function getPagerFn(){
				return paginationSrv.getPaginationStateConfig('pagination', 'mailinglists');
			}

			$scope.getDataMailingLists = function () {
				organizatorSrv.getMailingLists(angular.copy($scope.paginator)).then(function successCallback(response) {
					if (response.data.success) {
						$scope.setActiveList({participants:[]});
						$scope.model.listsData = response.data;
						$scope.model.listsData.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};
			$scope.openList = function (listData) {
				organizatorSrv.getMailingListById(listData.id).then(function successCallback(response) {
					if (response.data.success) {
						$scope.setActiveList(response.data.result);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};

            $scope.addNewList = function () {
				$scope.model.listsData.newEntry={};
				setTimeout(function () {
					window.document.getElementById('newMailingListInput').focus();
				}, 100);
            };

			$scope.deleteNewList = function () {
				delete $scope.model.listsData.newEntry;
			};

			$scope.saveNewList = function () {
				const title = $scope.model.listsData.newEntry.title;
				if (title && title.length>0) {
					organizatorSrv.saveMailingList(title).then(function successCallback(response) {
						if (response.data.success) {
							const list = response.data.result;
							$scope.deleteNewList();
							$scope.model.listsData.items.unshift(list);
							$scope.setActiveList(list);

							$scope.model.listsData.total = $scope.model.listsData.total+1;
							$scope.alertObjMailingLists = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
							$scope.model.listsData.totalPages = Math.ceil($scope.model.listsData.total / $scope.paginator.itemsPerPage);
							updatePaginatorFn();
						} else {
							$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
						}
					}, function errorCallback(response) {
						console.log(response);
					});
				}
			};


			$scope.editTitle = function (index,data) {
				$scope.model.listsData.editEntry={
					id:data.id,
					title:data.title
				};
				setTimeout(function () {
					window.document.getElementById('editMailingListTitle_' + index).focus();
				}, 100);
			};

			$scope.cancelEditTitle = function () {
				$scope.model.listsData.editEntry={};
			};

			$scope.renameList = function (data,event) {
				//blur срабатывает раньше click, пришлось переделать на mousedown
				if(event&&event.which !== 1)
					return;
				var newTitle=$scope.model.listsData.editEntry.title;
				organizatorSrv.renameMailingList($scope.model.listsData.editEntry.id,$scope.model.listsData.editEntry.title).then(function successCallback(response) {
					if (response.data.success) {
						data.title=newTitle;
						if(data.id==$scope.model.activeList.id)
							$scope.model.activeList.title=newTitle;
						$scope.model.listsData.editEntry={};
					} else {
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};

			$scope.deleteList = function (index,data) {
				organizatorSrv.deleteMailingList(data.id).then(function successCallback(response) {
					if (response.data.success) {
						if(data.id==$scope.model.activeList.id)
							$scope.setActiveList({participants:[]});
						$scope.model.listsData.items.splice(index, 1);
						$scope.model.listsData.total=$scope.model.listsData.total-1;
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response,'Удаление произведено успешно!');
						$scope.model.listsData.totalPages = Math.ceil($scope.model.listsData.total / $scope.paginator.itemsPerPage);
						$scope.deleteNewList();
					} else {
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};

			$scope.addNewParticipant = function () {
				delete $scope.model.activeList.newEntry;
				$scope.showParticipantSelect = !$scope.showParticipantSelect;
				setTimeout(function () {
					window.document.getElementById('newParticipantMailingListInput').focus();
				}, 100);
			};

			$scope.deleteNewParticipant = function () {
				delete $scope.model.activeList.newEntry;
				paginationSrv.clearPaginationStateConfig('participantsPaginator', 'orgParticipantList');
			};

			$scope.saveParticipant = function () {
				const inn = $scope.model.activeList.newEntry?.inn;
				if (inn && inn.length>0) {
					const innError = $scope.checkInn(inn);
					if (innError != null) {
						$scope.alertObjMailingLists = alertSrv.getErrorMsg(innError);
						return;
					}
					organizatorSrv.addParticipantToMailingList($scope.model.activeList.id, inn, $scope.model.activeList.newEntry.id).then((response) => {
						if (response.data.success) {
							$scope.model.activeList.participants.unshift({
								id:response.data.result,
								inn: inn,
								name: $scope.model.activeList.newEntry.name,
								kpp: $scope.model.activeList.newEntry.kpp
							});
							$scope.alertObjMailingLists = alertSrv.getAlertObj(response,'Адресат добавлен в список.');
							$scope.model.activeList.totalPages = Math.ceil($scope.model.activeList.participants.length / $scope.participantsPaginator.itemsPerPage);
							$scope.deleteNewParticipant()
						} else {
							$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
							$scope.model.activeList.newEntry.inn="";
						}
					}, (response) => {
						console.log(response);
					});
				}
			};

			$scope.checkInn = function (inn) {
				let error = null;
				if (/^0+$/.test(inn)) {
					error="Некорректно указан ИНН/УИН";
				}
				else {
					angular.forEach($scope.model.activeList.participants, (item) => {
						if (inn === item.inn) {
							error = 'Участник с таким ИНН/УИН уже добавлен в список.';
						}
					});
				}
				return error;
			};

			$scope.deleteParticipant = function (index,data) {
				organizatorSrv.deleteParticipantFromList($scope.model.activeList.id,data.id,data.name).then(function successCallback(response) {
					if (response.data.success) {
						$scope.model.activeList.participants.splice(index, 1);
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response,'Адресат удален из списка.');
						$scope.model.activeList.totalPages = Math.ceil($scope.model.activeList.participants.length / $scope.participantsPaginator.itemsPerPage);
						$scope.deleteNewParticipant();
					} else {
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};

			$scope.setActiveList=function(activeListData){
				$scope.model.activeList = activeListData;
				$scope.model.activeList.participants = $scope.model.activeList.participants||[];
				$scope.model.activeList.totalPages = Math.ceil($scope.model.activeList.participants.length / $scope.participantsPaginator.itemsPerPage);
				$scope.participantsPaginator.page=0;
			};

			/**
			 * Поиск по спискам КО
			 */
			$scope.inputSearchList = function (reset) {
				$scope.getDataMailingLists();
				if (reset) {
					$scope.paginator.page = 0;
				}
			};

			/**
			 * Обновление пагинатора
			 */
			function updatePaginatorFn() {
				$scope.totalPages = ($scope.model.activeList.participants.length) ? Math.ceil($scope.model.activeList.participants.length / $scope.paginator.itemsPerPage) : null;
			}

			function _initDictionaryBase() {
				$scope.dictionary = dictionarySrv.getRemoteData();
				$scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
				dictionarySrv.resetPaginatedDict($scope);
			}

			function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
				if (!value) {
					dictionarySrv.resetPaginatedDict($scope);
					delete $scope.hasInnError;
				} else if(value?.length>=8) {
					dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd).then((response)=>{
						if(!response.data.items || response.data.items.length === 0) {
							$scope.alertObjMailingLists = alertSrv.getErrorMsg("Организация с таким ИНН/УИН не найдена.");
							$scope.hasInnError = true;
						} else {
							delete $scope.hasInnError;
						}
					});
				} else {
					$scope.dictPaginated.orgParticipantList = [];
					$scope.totalPages = 0;
					delete $scope.hasInnError;
				}
			}
		}
	]
});