/**
 * Директива для списка ui-select. Ограничение длины поиска
 */
angular.module('uetp').directive('maxLength', [function () {
    return {
        require: 'ngModel',
        priority: 1,
        link: function (scope, element, attrs, ctrl) {
            if (attrs['maxLength']) {
                const $uiSelect = angular.element(element[0].querySelector('.ui-select-search'));
                $uiSelect.attr("maxLength", attrs.maxLength);
            }
        }
    };
}]);