/**
 * @author: sergeyu
 * Компонент верхнего меню
 */
angular.module('uetp')
    .component('headertoolbar', {
        templateUrl:['constants', function (constants) {
            return 'app/components/header/headerViews/' + constants.configProject.type + '.html'
        }],
        controller: ['$scope', '$rootScope', '$state', '$window', '$document', 'authSrv', 'desktopSrv', 'dictionarySrv', '$interval',
            '$uibModal', 'alertSrv', 'propertiesService', 'constants', '$timeout', 'profileSrv', 'dialogSrv',
            function ($scope, $rootScope, $state, $window, $document, authSrv, desktopSrv, dictionarySrv, $interval,
                      $uibModal, alertSrv, propertiesService, constants, $timeout, profileSrv, dialogSrv) {
                $scope.constants = constants;
                $rootScope.$on("exitEvent", function ($event, auth) {
                    desktopSrv.initCabinet(auth);
                });
                $rootScope.$on("openLoginEvent", function ($event, param) {
                    openLoginModalFn(param);
                });
                $rootScope.$on("openLoginPanelEvent", function () {
                    $scope.status.isOpenLogin = true;
                });
                this.$onInit = async function () {
                    initProperties();
                    init();
                    initHandlers();
                    // Вход через ЭМ
                    if (desktopSrv.getCookie('Redirect-Token') && !desktopSrv.getCookie('Auth-Token')) {
                        $state.go('webmarketLogin');
                        return;
                    }

                    const auth = await authSrv.checkedAuth(true);
                    desktopSrv.initCabinet(auth, false);
                    desktopSrv.checkBankGuaranteesParams();
                    authSrv.isCloseTariffPanel(authSrv.getUser().id);
                    if(auth && authSrv.getUser()?.id) {
                        checkMchd();
                    }
                };
                $scope.alertObjServerError = {};
                //tmp link renew
                $rootScope.$on("stateChanged", function () {
                    $rootScope.isBecomeBgAgent = $state.current.name === 'becomeBgAgent';
                    $rootScope.isBgAdvertising = $state.current.name === 'bgAdvertising';
                    desktopSrv.scroll();
                    authSrv.checkedAuthEventStop(true).then(function (auth) {
                        $scope.auth = auth;
                    });
                });
                $scope.$state = $state;
                $scope.auth = authSrv.getAuth();
                $scope.openLogin = openLoginFn;
                $scope.closeLogin = closeLoginFn;
                $scope.isDemoMode = isDemoModeFn;
                $scope.getProps = getPropertyFn;
                $scope.exit = authSrv.exit;
                $scope.windowWidth = window.innerWidth;
                $scope.data = {
                    url: ''
                };
                $scope.procId = '';
                $scope.id = '';

                angular.element($window).bind('resize', function () {
                    $scope.windowWidth = window.innerWidth;
                });

                function initProperties(){
                    propertiesService.getProperty("demo.mode");
                    propertiesService.getProperty("bankguarantee.enabled");
                    propertiesService.getProperty('main.url.uetp');
                    propertiesService.getProperty('main.url.ppk');
                    propertiesService.getProperty('crossauth.market');
                    propertiesService.getProperty('email.url');
                    propertiesService.getProperty('eis_exchange_rest.purchaseNoticeAEMethodCode');
                    propertiesService.getProperty("uem.url");
                    propertiesService.getProperty("sendClaimFreeEnable");
                    propertiesService.getProperty("api.url");
                    propertiesService.getProperty("mchd.url");
                    propertiesService.getProperty("mchd.import_packages");
                }

                function init() {
                    $scope.user = {};
                    $scope.cabType = desktopSrv.getCabType;

                    $scope.navbarCollapse = false;
                    $scope.status = {
                        isOpen: false,
                        isOpenLogin: false
                    };
                    $scope.certCheck = authSrv.isCertAvailable;
                    $scope.initHeaderMsg = true; // инициализация шапки с сообщениями должна произойти после инициализации header-toolbar
                }

                function initHandlers() {
                    // Set a timeout to allow the DOM to fully load before attaching event listeners
                    setTimeout(function () {
                        // Attach a mousedown event listener to the document
                        angular.element($document).on('mousedown', function (event) {
                            handleLogin(event);
                            handleNavbarCollapse(event);
                            $scope.$apply();
                        });
                    }, 100);
                }

                function checkMchd() {
                    if(['organizer_admin', 'organizer_user', 'participant_admin', 'user'].includes(authSrv.getUser().userRole)) {
                        profileSrv.mchdLoginCertCheck().then(checkRes => {
                            if (!checkRes.data.success) {
                                if (checkRes.data.errorCode === '430') {
                                    dialogSrv.openModal('_confirmInfoExt.html', {
                                        title: "Внимание!",
                                        message: checkRes.data.errorMessage,
                                        buttons: [{
                                            title: "Продолжить",
                                            btnClass: "btn-success",
                                            closeValue: false,
                                            action: function () {
                                                profileSrv.logUserMchd();
                                                $rootScope.$emit('mchdError', false);
                                            }
                                        }, {
                                            title: "Отмена",
                                            btnClass: "btn-light",
                                            closeValue: true
                                        }]
                                    });
                                } else if (checkRes.data.errorCode === '427') {
                                    authSrv.updateMchdCheckResult(checkRes.data.errorMessage);
                                    $rootScope.$emit('mchdError', true, checkRes.data.errorMessage);
                                }
                            }
                        });
                    }
                }

                function handleLogin(event) {
                    if ($scope.status.isOpenLogin && event.target && event.target.id !== "login-btn") {
                        if (!event.target.closest('#login-form')) {
                            $scope.closeLogin();
                        }
                    }
                }

                function handleNavbarCollapse(event) {
                    if ($scope.navbarCollapse && event.target) {
                        const isLinkClicked = event.target.tagName === 'A';
                        const isInsideNavbar = event.target.closest('#navbar') || event.target.closest("#navbarToggle");
                        const isDropdownToggleClicked = event.target.classList.contains('dropdown-toggle');
                        const isDropdownMenuClicked = event.target.closest('.dropdown-menu');

                        if (isInsideNavbar) {
                            if (isLinkClicked && !isDropdownToggleClicked && !isDropdownMenuClicked) {
                                $scope.changeNavbarState();
                            }
                        } else {
                            $scope.changeNavbarState();
                        }

                        if (isDropdownToggleClicked) {
                            event.stopPropagation();
                        }
                    }
                }

                $rootScope.$on("extCloseMenu", function () {
                    $scope.navbarCollapse = false;
                });

                $scope.changeNavbarState = () => {
                    $scope.navbarCollapse = !$scope.navbarCollapse;
                    $timeout(function () {$rootScope.$emit('navbarCollapse', $scope.navbarCollapse)});
                }

                function isDemoModeFn(){
                    return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
                }

                function getPropertyFn(name){
                    return $rootScope.properties && $rootScope.properties[name];
                }

                /**
                 * Открыть форму логина и следить за его закрытием
                 */

                function openLoginFn(data) {
                    if($state.current.name === 'tabletLogin'){
                        return;
                    }
                    data ? $scope.data.url = data : $scope.data.url = null;
                    if (!$scope.status.isOpenLogin) {
                        $scope.status.isOpenLogin = true;
                        // следим за кликом. Если он не по форме логина, то закрываем
                        setTimeout(function () {
                            angular.element($document).on('mousedown', function (event) {
                                if (event.target && event.target.id !== "login-btn") {
                                    if (!event.target.closest('#login-form')) {
                                        $scope.closeLogin();
                                        $scope.$apply();
                                    }
                                }
                            });
                        }, 100);
                    } else {
                        $scope.closeLogin();
                    }
                }

                function openLoginModalFn(param) {
                    $uibModal.open({
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        backdrop  : 'static',
                        keyboard  : true,
                        size: 'sm-login-centered',
                        templateUrl: 'loginModal.html',
                        controller:['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                            $scope.data = {
                                url: null,
                                procType: null,
                                procId: null,
                                lotId: null,
                                openFactoring: null,
                                activeProfileTab: null
                            };

                            $scope.closeLogin = function () {
                                $uibModalInstance.close();
                            };

                            if (param) {
                                if (param.state) {
                                    $scope.data = param;
                                } else {
                                    $scope.data.url = param.url;
                                    $scope.data.procType = param.procType;
                                    $scope.data.procId = param.procId;
                                    if (param.lotId) {
                                        $scope.data.lotId = param.lotId;
                                    }
                                    if (param.openFactoring) {
                                        $scope.data.openFactoring = param.openFactoring;
                                    }
                                    if (param.activeProfileTab) {
                                        $scope.data.activeProfileTab = param.activeProfileTab;
                                    }
                                }
                            } else {
                                $scope.stateUrl = null;
                                $scope.data.url = null;
                            }

                            $scope.data.isMobile= window.innerWidth <= 991;
                        }]
                    }).result['catch'](function (res) {
                        if (!(res === 'cancel' || res === 'escape key press')) {
                            throw res;
                        }
                    });
                }

                /**
                 * Закрываем форму логина
                 * @private
                 */
                function closeLoginFn() {
                    // angular.element($document).off('click');  //UETP-372
                    if ($scope.status.isOpenLogin) {
                        $scope.status.isOpenLogin = false;
                    }
                }

                $scope.closeMenu = function () {
                    $scope.navbarCollapse = !$scope.navbarCollapse
                };
            }]
    });

