/**
 * @author: sergeyu
 * Профиль пользователя. Добавление нового представителя
 */
angular.module('uetp')
    .component('agentEditor', {
        templateUrl: 'app/components/profile/agents/agentEdit/agentEditor.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dictionarySrv', 'alertSrv', 'onlineCheckSrv', 'dialogSrv', 'needConfirmSrv','httpSrv','bgAgentSrv',
        	         'bgBankSrv', 'organizatorSrv', 'drapdropPanelSrv', 'propertiesService',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dictionarySrv, alertSrv, onlineCheck, dialogSrv, needConfirmSrv, httpSrv,bgAgentSrv,
            		 bgBankSrv, organizatorSrv, drapdropPanelSrv, propertiesService) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.modelViewAgent = {isEdit: false};
                    $scope.form = {agentForm: {}};
                    $scope.agentSaveError = null;
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.userCertificates = {};
                    $scope.userMchds = {};
                    $scope.agent = {};
                    $scope.agentSaved = {};
                    $scope.defaultUserRole = null;
                    $scope.attachItemName = '';
                    $scope.downloadFile = httpSrv.downloadFile;
                    $scope.isAgent = authSrv.isUserAgent;
                    $scope.isUserBank =  authSrv.isUserBank;
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')[$scope.cabType()]
                        .sort((a, b) => {
                            return a['name']>b['name'] ? 1 : a['name']<b['name'] ? -1 : 0;
                        });
                    $scope.showDragPanel = false;
                    $scope.hasChangeCustomers = drapdropPanelSrv.hasChanges;

                    propertiesService.getProperty("mchd.import_packages").then((value) => {
                        $scope.mchdInfo = 'В данном разделе пользователь может добавить машиночитаемую доверенность, выпущенную в едином формате, для подтверждения полномочий после добавления сертификата.';
                        if (value === 'true') {
                            $scope.mchdInfo += "\nЕсли доверенность выпущена в порядке передоверия, то архив должен дополнительно содержать файлы цепочки передоверия.";
                        }
                    });

                    if ($stateParams.id) {
                        $scope.modelViewAgent.isEdit = true;
                        profileSrv.getAgentById($stateParams.id).then(function (response) {
                            if (response.data.success) {
                                $scope.agent = response.data.result;
                                profileSrv.appendAllows($scope.agent);
                                $scope.agentSaved = angular.copy($scope.agent);
                                $scope.defaultUserRole = $scope.agent.userRole;
                                if (['participant', 'organizer'].includes($scope.cabType())) {
                                    profileSrv.loadCertificatesByUserId($scope.userCertificates, $stateParams.id);
                                }
                                if($scope.cabType() === 'admincab' && $scope.agent.userRole === 'operator_manager' && $scope.agent.id) {
                                    $scope.agent.dataPanelCustomer = {};
                                    $scope.getDataDropPanels();
                                }
                                if(['organizer_admin', 'organizer_user', 'participant_admin','user'].includes($scope.agent.userRole)) {
                                    propertiesService.getProperty("mchd.url").then((host) => {
                                        if (!host.endsWith('/')) host += '/';
                                        $scope.mchdUrl = host;
                                        profileSrv.loadAgentMchds($scope.userMchds, $scope.agent.id);
                                    });

                                }
                            }
                        })
                    } else {
                        $scope.agent.userRole = $scope.cabType() === 'admincab' ? 'operator_admin' : $scope.cabType() === 'organizer' ? 'organizer_user' : 'user';
                        $scope.userRoleChange();
                    }

                    $scope.isCertChanged = false;

                    if($scope.cabType() === 'organizer') {
                        var rList = [];
                        angular.forEach($scope.roleList, function (role) {
                            if(role.id!=='organizer_cbz')
                                rList.push(role);
                        })
                        $scope.roleList = rList;

                        if(!$scope.agent.userProperties)
                            $scope.agent.userProperties =
                                { requests_processing_responsible: 'false', procedureWorkflow:'true', contractWorkflow: 'true',
                                    signContractEnabled: 'true', sendContractEmail: 'false', allowed_223: 'true', allowed_44: 'true', allowed_commercial: 'true'};
                    }

                    $scope.changedModel = false;
                    $scope.changedFile = false;
                };
                $scope.changeUserRole = function(userRole) {
                    if (userRole.$select.selected.id === 'operator_manager' && $scope.agent.id)
                        $scope.getDataDropPanels();
                    $scope.userRoleChange();
                }

                $scope.saveCustomer = function (data) {
                    return organizatorSrv.saveUserCustomer($scope.agent.id, data);
                };

                $scope.getDataDropPanels = function () {
                    organizatorSrv.getDataUserManagerCustomer($scope.agent.id||$stateParams.id).then(function (response) {
                        if (response.data.success) {
                            $scope.agent.dataPanelCustomer = {
                                dataLeft: response.data.result.allCustomer,
                                dataRight: response.data.result.assignedCustomer
                            };
                            $scope.showDragPanel = true;
                        }
                    });
                };

                $scope.$watch("agent", function () {

                    setTimeout(function () {
                        if ($scope.agent.email) {
                            $scope.changedModel = true;
                        }
                        $scope.message = document.getElementById('emailAgent').title;
                        if ($scope.message && ($scope.form.agentForm.email.length || $scope.agent.email)) {
                            $scope.alertObjProfileAgentEditEmail = alertSrv.getErrorMsg($scope.message);
                        }
                    }, 500);
                }, true);
                $scope.saveNewAgent = saveNewAgentFn;
                $scope.goBack = goBackFn;
                $scope.cancel = cancelFn;
                $scope.downloadPowerOfAttorney = downloadPowerOfAttorneyFn;
                $scope.deletePowerOfAttorney = deletePowerOfAttorneyFn;
                $scope.uploadPowerOfAttorney = uploadPowerOfAttorneyFn;
                $scope.onPersonalDataChanged = onPersonalDataChangedFn;

                function goBackFn() {
                    history.back();
                }

                $scope.saveCustomerData = function (data) {
                    $scope.agent.dataPanelCustomer.dataRight = data;
                };

                $scope.userRoleChange = function(){
                    if(!$scope.agent.userProperties)
                        $scope.agent.userProperties =
                            { requests_processing_responsible: 'false', procedureWorkflow:'true', contractWorkflow: 'true',
                                signContractEnabled: 'true', sendContractEmail: 'false', allowed_223: 'true', allowed_44: 'true', allowed_commercial: 'true'};

                    if(['organizer_user', 'organizer_admin', 'operator_admin'].includes($scope.agent.userRole)){
                        $scope.agent.userProperties.allowed_223 = 'true';
                        $scope.agent.userProperties.allowed_44 = 'true';
                        $scope.agent.userProperties.allowed_commercial = 'true';
                    } else {
                        delete $scope.agent.userProperties.allowed_223;
                        delete $scope.agent.userProperties.allowed_44;
                        delete $scope.agent.userProperties.allowed_commercial;
                    }
                }

                /**
                 * Добавления нового представителя организации
                 * @param agent
                 */
                function saveNewAgentFn() {
                    if ($scope.agent.middleName === undefined || $scope.agent.middleName === "undefined") {
                        $scope.agent.middleName = '';
                    }

                    if (!(($scope.form.agentForm.$dirty || $scope.hasChangeCustomers()) && $scope.form.agentForm.$valid)) {
                        $scope.alertObjProfileAgentEdit = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }
                    const confirmText = ($scope.agentSaved?.firstName!==$scope.agent?.firstName || $scope.agentSaved?.lastName!==$scope.agent?.lastName || $scope.agentSaved?.middleName!==$scope.agent?.middleName) &&
                                            ($scope.agentSaved?.mchdId || $scope.agentSaved?.certId) && !$scope.disactivateFIO ?
                        "Были внесены изменения в ФИО пользователя. После сохранения изменений нужно будет зарегистрировать сертификат этого пользователя и, при необходимости, пользователю добавить МЧД. Сохранить изменения?":
                        'Выполнить действие: ' + ($scope.modelViewAgent.isEdit?'Сохранить изменения':'Зарегистрировать') + '?';

                    dialogSrv.openModal('_confirmInfoExt.html', {
                            title: "Сообщение",
                            message: confirmText,
                            buttons: [{
                                title: $scope.modelViewAgent.isEdit?'Сохранить изменения':'Зарегистрировать',
                                btnClass: "btn-success",
                                closeValue: false,
                                action: function () {
                                    _saveOrUpdateAgent();
                                }
                            },{
                                title: "Отмена",
                                btnClass: "btn-light",
                                closeValue: true
                            }]
                        });

                    function _saveOrUpdateAgent() {
                        const dataObj = angular.copy($scope.agent);
                        dataObj.mchdReq = $scope.mchdReq;
                        dataObj.parentOrgId = $scope.user.id;

                        if($stateParams.list_count === 1 && ['participant_admin','organizer_admin'].includes($scope.defaultUserRole) && $scope.defaultUserRole !== $scope.agent.userRole){
                            $scope.alertObjProfileAgentEdit = alertSrv.getErrorMsg('Пользователь с ролью "Администратор" единственный. Смена роли пользователя запрещена.');
                            return;
                        }

                        const saveAgent = $scope.isAgent() ? bgAgentSrv.saveNewAgent($scope.user.id, dataObj) :
                            ($scope.isUserBank() ? bgBankSrv.saveNewAgent($scope.user.id, dataObj) : profileSrv.saveNewAgent(dataObj));
                        saveAgent.then(function successCallback() {

                            if($scope.cabType() === 'admincab' && $scope.agent.userRole === 'operator_manager' && $scope.agent.id) {
                                $scope.saveCustomer($scope.agent.dataPanelCustomer.dataRight).then(function (response){
                                    if (response.data.success)
                                        onSuccessSave(dataObj);
                                    else {
                                        $scope.alertObjProfileAgentEdit = alertSrv.getAlertObj(response);
                                        console.log(response);
                                    }
                                })
                            } else
                                onSuccessSave(dataObj);
                        }, function errorCallback(response) {
                            $scope.alertObjProfileAgentEdit = alertSrv.getAlertObj(response);
                            console.log(response);
                        });
                    }

                    function onSuccessSave(dataObj){
                        const text = dataObj.id ? 'Изменения успешно сохранены' : "На электронный адрес " + $scope.agent.email + " отправлено письмо. " +
                            " Для завершения регистрации новый представитель должен перейти по указанной в письме ссылке.";
                        dialogSrv.showDialogMsgHtml($scope, text, function () {
                            $scope.form.agentForm.$setPristine();
                            $state.go('profile');
                        });
                    }
                }

                $scope.onSizeError = function (files) {
                    $scope.alertObjProfileAgentEditSert = alertSrv.getErrorMsg("Сертификат " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!");
                };
                $scope.showFiles = function (files) {
                    $scope.attachItemName = files[0].name;
                };
                $scope.uploadFile = function (response) {
                    if (response.data.success) {
                        $scope.disactivateFIO = true;

                        const result = response.data.result,
                            arr = result.name.split(' '),
                            prev = angular.copy($scope.agent);

                        $scope.mchdReq = ['406_2_2', '406_3'].includes(response.data.errorCode);
                        if(['406_2_2', '406_3'].includes(response.data.errorCode) && !$scope.modelViewAgent.isEdit && response.data?.errorMessage) {
                            response.data.errorMessage = response.data.errorMessage.replace("Сохранить изменения", "Зарегистрировать");
                        } else if(!$scope.modelViewAgent.isEdit) {
                            response.data.errorMessage = 'Сертификат добавлен!<br/>Для завершения нажмите кнопку Зарегистрировать.';
                        }


                        if($scope.agent.firstName !== arr[1] || $scope.agent.lastName !== arr[0] || $scope.agent.middleName !== (arr.length === 3 ? arr[2] : '')) {
                            onPersonalDataChangedFn();
                            $scope.isCertChanged = true;
                        }

                        $scope.agent.firstName = arr[1];
                        $scope.agent.lastName = arr[0];
                        $scope.agent.middleName = arr.length === 3 ? arr[2] : '';
                        $scope.agent.certId = response.data.message;

                        if ($scope.modelViewAgent.isEdit === false) {
                            $scope.agent.email = result.email;
                        } else {
                            $scope.agent.email = prev.email;
                        }
                        if ($scope.form.agentForm) $scope.form.agentForm.$setDirty();
                    } else {

                        $scope.agent.firstName = '';
                        $scope.agent.lastName = '';
                        $scope.agent.middleName = '';
                        $scope.agent.certId = '';
                        $scope.agent.email = '';
                    }
                };

                function uploadPowerOfAttorneyFn(response) {
                    if (response && response.length > 0) {
                        $scope.agent.powerOfAttorney = {
                          fileName: response[0].fileName,
                          id: response[0].id
                        };
                        $scope.changedFile = true;
                    }
                }

                function downloadPowerOfAttorneyFn() {
                    profileSrv.downloadUserPowerOfAttorneyAgentByFileId($scope.agent.powerOfAttorney.id);
                }

                function deletePowerOfAttorneyFn() {
                    $scope.agent.powerOfAttorney = null;
                    $scope.changedFile = true;
                }

                function onPersonalDataChangedFn(){
                    delete $scope.agent.consentProcessing;
                    delete $scope.agent.consentDissemination;
                    $scope.agent.consentProcessingDate = null;
                    $scope.agent.consentDisseminationDate = null;
                    $scope.agentSaved.consentProcessingDate = null;
                    $scope.agentSaved.consentDisseminationDate = null;
                }

                function cancelFn() {
                    if ($scope.form.agentForm.$dirty) {
                        needConfirmSrv.call($scope.goBack, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        $scope.goBack();
                    }
                }

                $scope.createMchd = (url) => {
                    propertiesService.getProperty("mchd.url").then((host) => {
                        const a = document.createElement("a");
                        a.href = host + url;
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function () {
                            document.body.removeChild(a);
                        }, 500);
                    });
                }
                $scope.onSizeMchdError = function(files, maxfilesize){
                    angular.forEach(files, function (file) {
                        var errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                            'Превышен максимально разрешенный размер файла ('
                            + (maxfilesize /1048576 ).toFixed(2) + ' Мб)!<br />' +
                            'Размер файла ' + file.name + ': ' + (file.size / 1048576).toFixed(2) + ' Мб.';
                        $scope.alertObjAgentMchd = alertSrv.getErrorMsg(errorMsg)
                    });
                };
                $scope.onSizeEmptyMchdError = function(files){
                    angular.forEach(files, function (file) {
                        if (file.size === 0) {
                            var errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                'Невозможно добавить пустой файл.';
                            $scope.alertObjAgentMchd = alertSrv.getErrorMsg(errorMsg)
                        }
                    });
                };
                $scope.onFileNameMchdError = function(files){
                    angular.forEach(files, function (file) {
                        var errorMsg = 'Импорт из файла  ' + file.name + ' невозможен.<br />' +
                            'Для импорта используйте файлы формата xml.';
                        $scope.alertObjAgentMchd = alertSrv.getErrorMsg(errorMsg)
                    });
                };
                $scope.mchdImport = (response) => {
                    if(response.success) {
                        if(['003', '004', '038'].includes(response.result?.code) || response.result?.attorney) {
                            if (response.message) {
                                dialogSrv.openModal('_confirmInfoExt.html', {
                                        title: "Подтверждение",
                                        message: response.message,
                                        buttons: [{
                                            title: "Подтверждаю",
                                            btnClass: "btn-success",
                                            closeValue: false,
                                            action: function () {
                                                acceptMchd(response, function(){$scope.alertObjUserInfoMchd = alertSrv.getSuccessMsg('Доверенность добавлена');});
                                            }
                                        },{
                                            title: "Отмена",
                                            btnClass: "btn-light",
                                            closeValue: true,
                                            action: function () {
                                                $scope.alertObjAgentMchd = alertSrv.getErrorMsg("Добавление доверенности отменено");
                                            }
                                        }]
                                    });
                            } else {
                                acceptMchd(response);
                                $scope.alertObjAgentMchd = alertSrv.getSuccessMsg((['003','004','038'].includes(response.result?.code) || (!response.result?.code && !response.result?.message)) ? 'Доверенность добавлена' : response.result?.message);
                            }

                        } else {
                            profileSrv.loadUserMchds($scope.userMchds, $scope.agent.id);
                            $scope.alertObjAgentMchd = alertSrv.getErrorMsg(response.result.message);
                        }
                    }
                    function acceptMchd(response, callback){
                        const params = {
                            userId: $scope.agent.id,
                            mchdId: response.result?.attorney?.id,
                            issued: response.result?.attorney?.issued,
                            expired: response.result?.attorney?.expired,
                            state: response.result?.attorney?.state?.code
                        }
                        profileSrv.updateUserMchd($scope.userMchds, params).then(function success(){
                            profileSrv.loadUserMchds($scope.userMchds, $scope.agent.id);
                            if(callback) callback();
                        });
                    }
                }
                $scope.getAgentInfo = () => {
                    return $scope.user.inn ? {"inn": $scope.user.inn, "orgId": $scope.user.id} : {};
                }
                $scope.hasActiveCert = () => {
                    return $scope.agent.certId ? $scope.userCertificates?.storedCerts?.find(cert=>cert.id===$scope.agent.certId) : false;
                }
                $scope.getAgentInn = () => {
                    return {"principalInn": $scope.user.inn, "agentInn": $scope.hasActiveCert() ? $scope.userCertificates?.storedCerts?.find(cert=>cert.id===$scope.agent.certId)?.inn : undefined, "userId": $scope.agent.id, "token": localStorage.getItem("key")}
                }
                $scope.isMchdDisabled = () => !$scope.agent.mchdReq || !$scope.hasActiveCert()
                $scope.getMchdLink = (mchd) =>
                    $scope.mchdUrl + 'full?poanum=' + mchd.mchdId + '&principal=' + $scope.user.inn + '&agent=' + mchd.agentInn;
            }]

    });

