/**
 * @author: alexandrbu
 * Переторжки 
 */
angular.module('uetp')
    .component('rebiddingsList', {
        templateUrl: 'app/components/procedures/rebiddings/rebiddings.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'authSrv', 'alertSrv', '$interval','needConfirmSrv','dialogSrv', '$filter', 'profileSrv', 'dictionarySrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, authSrv, alertSrv, $interval, needConfirmSrv, dialogSrv, $filter, profileSrv, dictionarySrv) {
                var ctrl = this,
                    refreshIndex = null;
                const mchdErrors = ['427','429','430_2'];

                this.$onInit = function () {
                    ctrl.alerts = {};
                    $scope.ctrl = ctrl;
                    $scope.auth = authSrv.getAuth();
                    $scope.getCabType = desktopSrv.getCabType;

                    $scope.auctions = [];
                    
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        itemsPerPage: 50,
                        page: 0
                    };
                    $scope.maxCounter = 10;
                    
                    $scope.emptyMoney = ['0','0,','0.','0.0','0,0','0.00','0,00','0.000','0,000','0.0000','0,0000','',' ',undefined];
                    
                    $scope.model = {
                            isOpen: {},
                            counter: {},
                            counterText: {},
                            indexes: {},
                            currencyCode: {},
                            cancelBiddingsArr: [], // лоты по котором торги отменены
                            cancelBiddingsText: '' // лоты по котором торги отменены
                        };
                    proceduresSrv.getProcedure().then(function (response) {
                        if (response.data.success)
                            $scope.procedure = response.data.result;
                    });
                    proceduresSrv.getRebiddingsByProcedure($scope.paginator).then(function (response) {
                        if (response.data.success) {
                            $scope.rebiddings = response.data.items;

                            refreshIndex = $interval(function () {
                                $scope.refreshAllRebiddings();
                            }, 10000);
                        }
                    });
                };
                $scope.changeSort = changeSortFn;
                $scope.totalPagesCheck = totalPagesCheckFn;
                $scope.loadAuction = loadAuctionFn;
                 
                this.$onDestroy = function () {
                    $interval.cancel(refreshIndex);
                };
                
                function totalPagesCheckFn(data) {
                    return Math.ceil(data.length / $scope.paginator.itemsPerPage);
                }
                
                $scope.disableSendBid = function (auction) {
               		return auction.moneyError || $scope.emptyMoney.indexOf(auction.money)!=-1 || !$scope.procedure;
                }

                function loadScopeMth() {
                    var mth = $scope.startMth || $state.name + "Load";
                    if (typeof $scope[mth] === "function") $scope[mth](true);
                }

                function changeSortFn($event) {
                    var th = $event.currentTarget,
                        orderBy = th.getAttribute('st-sort'),
                        orderAsc = th.classList.contains("sortAsc");

                    [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                        column.classList.remove("sortDesc");
                        column.classList.remove("sortAsc");
                    });
                    orderAsc = !orderAsc;
                    th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
                    if (orderBy) {
                        //$scope.paginator.page = 0;   // если надо, то вернуть
                        $scope.paginator.orderBy = orderBy;
                        $scope.paginator.orderAsc = orderAsc;
                        proceduresSrv.setTableSort($state.name, {orderBy: orderBy, orderAsc: orderAsc});
                        loadScopeMth();
                    }
                }
                
                function loadAuctionFn(rebiddingId, expanded, $index) {
                	if(!expanded){
                		return;
                	}
                	if($scope.model.isOpen[$index]!=true){
                		return;
                	}
                	proceduresSrv.getAuctionsProgressByRebidding(rebiddingId).then(function (response) {
                        if (response.data.success) {
                            $scope.auctions[rebiddingId] = response.data.result;
                            $scope.model.currencyCode[$index] = _getCurrencyType($scope.auctions[rebiddingId]);
                          }
                    });
                }
                
                /**
                 * Отправить предложение
                 * @param index - индекс строки
                 * @param auction - аукцион
                 */
                 $scope.preSendBid = function (index, auction, priceOfferLastStage) {
                    const priceType = $scope.procedure.priceType;
                    if($scope.isPercentMode()){
                        const percentMode = $scope.getPercentMode(auction.lotNumber);
                        if (percentMode === 'MAX_PERCENT' && priceOfferLastStage && auction.money >= priceOfferLastStage && !auction.myLastBid) {
                            $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn('Предложенный процент/коэффициент не должен превышать или быть равным проценту/коэффициенту, предложенному на прежних этапах ' + $filter('percent')(priceOfferLastStage) + '.');
                            auction.money = '';
                        } else if (percentMode === 'MAX_PERCENT' && auction.myLastBid && auction.money >= auction.myLastBid) {
                            $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn('Подать ценовое предложение с ценой выше или равной ' + $filter('percent')(auction.myLastBid) + ' нельзя.');
                            auction.money = '';
                        } else if (percentMode === 'MIN_PERCENT' && priceOfferLastStage && auction.money <= priceOfferLastStage && !auction.myLastBid) {
                            $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn('Предложенный процент/коэффициент должен быть выше процента/коэффициента, предложенного на прежних этапах ' + $filter('percent')(priceOfferLastStage) + '.');
                            auction.money = '';
                        } else if (percentMode === 'MIN_PERCENT' && auction.myLastBid && auction.money <= auction.myLastBid) {
                            $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn('Подать ценовое предложение с ценой ниже или равной ' + $filter('percent')(auction.myLastBid) + ' нельзя.');
                            auction.money = '';
                        } else {
                            needConfirmSrv.call($scope.sendBid, [index, auction], 'Вы действительно хотите подать ценовое предложение с ценой ' +$filter('percent')(auction.money)+'?');
                        }
                    } else {
                        if (priceType === 'MAX_PRICE' && priceOfferLastStage && auction.money >= priceOfferLastStage && !auction.myLastBid) {
                            $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn('Предложенное ценовое предложение не должно превышать или быть равным цене, предложенной на прежних этапах ' + $filter('currency')(priceOfferLastStage, $scope.model.currencyCode[index]) + '.');
                            auction.money = '';
                        } else if (priceType === 'MAX_PRICE' && auction.myLastBid && auction.money >= auction.myLastBid) {
                            $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn('Подать ценовое предложение с ценой выше или равной ' + $filter('currency')(auction.myLastBid, $scope.model.currencyCode[index]) + ' нельзя.');
                            auction.money = '';
                        } else if (priceType === 'MIN_PRICE' && priceOfferLastStage && auction.money < priceOfferLastStage && !auction.myLastBid) {
                            $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn('Предложенное ценовое предложение должно быть выше цены, предложенной на прежних этапах ' + $filter('currency')(priceOfferLastStage, $scope.model.currencyCode[index]) + '.');
                            auction.money = '';
                        } else if (priceType === 'MIN_PRICE' && auction.myLastBid && auction.money <= auction.myLastBid) {
                            $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn('Подать ценовое предложение с ценой ниже или равной ' + $filter('currency')(auction.myLastBid, $scope.model.currencyCode[index]) + ' нельзя.');
                            auction.money = '';
                        } else {
                            needConfirmSrv.call($scope.sendBid, [index, auction], 'Вы действительно хотите подать ценовое предложение с ценой '+$filter('currency')(auction.money, $scope.model.currencyCode[index])+'?');
                        }
                    }
                 };
                 
                 $scope.sendBid = async function (index, auction) {
                     if($scope.errorMsg)
                         delete $scope.errorMsg;
                     const checkRes = await profileSrv.checkAccredSecured($stateParams.procType, 'true');
                     if (!checkRes.data.result) {
                         dialogSrv.openModal('_dialogInfo.html', {message: 'Отправка ценового предложения невозможна! Отсутствует или закончилась аккредитация у организации. <a ui-sref="profile({activeProfileTab:\'accreditation\'})" ng-click="closeModal()">Перейти</a>'})
                         return;
                     }
                     try{
                         const response = await proceduresSrv.signAndSendBid(auction.id, auction.money, $scope.auth.certId, "rebidding");
                         if (!response.data.success) {
                             if(mchdErrors.includes(response.data.errorCode)) {
                                 $scope.errorMsg = response.data.errorMessage.replace('\n', '<br/>');
                             } else {
                                 $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn(response.data.message ? response.data.message : response);
                             }
                             return;
                         }
                         if (!response.data.result.accepted)
                             $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn(response.data.result.bidStatusDesc);
                         else {
                             $scope.ctrl.alerts[index] = alertSrv.getAlertObj(response, response.data.result.bidStatusDesc);
                             $scope.updateAuction(index, auction, false);
                         }
                         auction.money = '';
                     } catch (reason) {
                         if(!reason.data.success && mchdErrors.includes(reason.data.errorCode)) {
                             $scope.errorMsg = reason.data.errorMessage.replace('\n', '<br/>');
                         } else if (typeof reason === 'string') {
                             $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn(reason);
                         } else {
                             $scope.ctrl.alerts[index] = reason.status === -1 ?
                                 alertSrv.getAlertObj(reason, 'Не удалось подать ценовое предложение. Проверьте подключение Вашего рабочего места к сети Интернет.') : alertSrv.getAlertObj(reason);
                         }
                     }
                };
                
                $scope.open = function (index) {
                    $scope.model.isOpen[index] = !$scope.model.isOpen[index];
                };
                
                /**
                 * Обновление хода аукциона
                 * @param index
                 * @param auction
                 * @param srollMoney - прокрутить к цене
                 */
                $scope.updateAuction = function (index, auction, srollMoney) {

                    return proceduresSrv.getAuctionAndTradeProgress(auction.id).then(function (response) {
                        if (response.data.success) {
                           for (var prop in auction) {
                                if (auction.hasOwnProperty(prop)) {
                                    if (prop === 'money' || prop === 'moneyError' || prop === 'perfectBid' || prop === 'myBid') {
                                        continue;
                                    }
                                    delete auction[prop];
                                }
                            }
                            angular.extend(auction, response.data.result);
                            if ($scope.model.counter[index] > $scope.maxCounter) {
                                $scope.model.counter[index] = auction.bids ? auction.bids.length : 0;
                            } else {
                                $scope.model.counter[index] = $scope.maxCounter;
                            }

                            if (srollMoney) {
                                setTimeout(function () {
                                    var element = window.document.getElementById('auctionMoney_' + index);
                                    desktopSrv.scrollIntoViewFn(element, {
                                        behavior: "smooth"
                                    }, 100);
                                });
                            }
                            return Promise.resolve(response)
                        }

                    })

                };
                
                $scope.updateRebidding = function (rebiddingId, index) {
                	
                	 return  proceduresSrv.getRebiddingById(rebiddingId).then(function (response) {
                         if (response.data.success) {
                        	 angular.extend($scope.rebiddings[index], response.data.result);
                        	 if(response.data.result && response.data.result.auctionJson){
                            	 
                            	 for (var prop in $scope.auctions[rebiddingId]) {
                                     if ($scope.auctions[rebiddingId].hasOwnProperty(prop)) {
                                         if (prop === 'money' || prop === 'moneyError' || prop === 'perfectBid' || prop === 'myBid') {
                                             continue;
                                         }
                                         delete $scope.auctions[rebiddingId][prop];
                                     }
                                 }
                            	 angular.extend($scope.auctions[rebiddingId], response.data.result.auctionJson);
                            	 
                            	 if ($scope.model.counter[index] > $scope.maxCounter) {
                                     $scope.model.counter[index] = $scope.auctions[rebiddingId].bids ? $scope.auctions[rebiddingId].bids.length : 0;
                                 } else {
                                     $scope.model.counter[index] = $scope.maxCounter;
                                 }
                            	 
                            	 var element = window.document.getElementById('auctionMoney_' + index);
                            	 if(element === document.activeElement){
                                	 setTimeout(function () {
	                            		 var element = window.document.getElementById('auctionMoney_' + index);
	                                     element.focus();
	                                 }, 1000);
                            	 }
                            	 return Promise.resolve(response);
                            	 //return true;
                             }
                          }
                     });
               };
                $scope.refreshAllRebiddings = function () {
                    if ($scope.model.indexes) {
                        angular.forEach($scope.model.indexes, function (auction, index) {
                            if (auction.lotState == 'preRebidding' || 
                            	auction.lotState == 'rebidding' || 
                            	auction.lotState == 'postRebidding') {
                            	$scope.updateRebidding(auction.id, index);
                            	
                              }
                        });
                    }
                };

                function _getCurrencyType(auction) {
                    return auction?.currency ? dictionarySrv.getCurrencyPatternByCode(auction.currency) : ''
                }
                
                $scope.viewFullTbl = function (element, index, auctions) {
                    if ($scope.model.counter[index] === $scope.maxCounter) {
                        $scope.model.counter[index] = auctions.length
                    } else {
                        $scope.model.counter[index] = $scope.maxCounter;
                    }
                    desktopSrv.scrollIntoViewFn(element.target, {
                        behavior: "smooth",
                        block: "end"
                    });
                };
                
                $scope.getTimeToEnd = function (endDate) {
                  /* var dateNow = Date.now();
                   if(dateNow>=endDate){
                	   return 0;
                   }*/
                   return endDate;
                };
                $scope.isTenToEnd = function (startDate) {
                     var dateNow = Date.now();
                     return dateNow<(startDate+1000*60*60*4)&&dateNow>=(startDate+1000*60*60*4-1000*60*10);
                  	
                  };
                $scope.isPercentMode = function(){
                    return $scope.procedure && $scope.procedure.priceMode==='percent';
                }
                $scope.getPercentMode = function(lotNumber){
                    return $scope.procedure.lots.filter(l=>(l.ordinalNumber+'')===lotNumber)[0].percentMode;
                }
            }]
    });

