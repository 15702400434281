/**
 * @author dmitrys
 * Сервис дл яработы с Расширенными полями
 */
angular.module('uetp').service('extendFieldSrv', ['$state', 'httpSrv', 'propertiesService','noticeSrv', 'permissionSrv',
    function ($state, httpSrv, propertiesService,noticeSrv, permissionSrv) {
        var service = this;

        /*
        * Шаблоны расширеных полей
        * NOTICE - уровень изщвещения
        * LOT - уровень лота
        * */
        var noticeExtFieldTemplate = {
            "NOTICE":[],
            "LOT":[]
        };
        
       /*
        * Признакт того что шаблон загружен
        * */
        service.loadTemplate = false;

        service.getNoticeExtFieldTemplate = getNoticeExtFieldTemplateFn;
        service.loadNoticeExtFieldTemplate = loadNoticeExtFieldTemplateFn;
        service.getExtendFieldForSave = getExtendFieldForSaveFn;
        service.getExtendFieldForModel = getExtendFieldForModelFn;
        service.setLoadTemplate = setLoadTemplateFn;
        service.isLoadTemplate = isLoadTemplateFn;
        service.clearNoticeExtFieldTemplate = clearNoticeExtFieldTemplateFn;
        service.setNoticeField = setNoticeFieldFn;
        service.setTemplateVersion = setTemplateVersionFn;
        service.setModelToTemplate = setModelToTemplateFn;
        // service.setGroups = setGroupsFn;
        service.getPurchaseMethod = getPurchaseMethodFn;
        service.getDict = getDictFn;
         
        service.dict = {
            /*
            * Валюта
            * */
            currency:[
                {code:'840', name:'Доллар США', pattern:'USD'},
                {code:'156', name: 'Юань', pattern: 'CNY'},
                {code:'643', name:'Рубль', pattern:'RUB'},
                {code:'978', name:'Евро', pattern:'EUR'}
            ]
        };

        /*
        * Получить справочник по типу
        * */
        function getDictFn(type) {
            return service.dict[type];
        }

        function getNoticeExtFieldTemplateFn(type){
            if(noticeExtFieldTemplate && noticeExtFieldTemplate[type]) return noticeExtFieldTemplate[type];
            return [];
        }
        
        /*
        * Данные для поля documnet.extendFields и lots.lot[i].extendFields в преобразованом виде для ЕИС
        * */
        function getExtendFieldForSaveFn(data) {
            var result = {};
            if(data) {
                result.noticeExtendField = [];
                angular.forEach(data, function (item) {
                    if (item.type==='BOOLEAN' || item.value) {
                        var extendField = {
                            extendField: [
                                {
                                    integrCode: item.integrCode,
                                    description: item.description,
                                    type: item.type,
                                    value: getValue(item)
                                }
                            ],
                            position : {
                            	tabOrdinal: item.tabOrdinal,
                            	sectionOrdinal: item.sectionOrdinal 
                            }
                        };
                        result.noticeExtendField.push(extendField);
                    }
                });
            }
            return result;
            function getValue(item) {
                switch (item.type) {
                    case 'DATETIME':
                        return {dateTime:item.value};
                    case 'BOOLEAN':
                        return {boolean: (angular.isUndefined(item.value) ? false : item.value)};
                    default:
                        var obj = {};
                        obj[item.type.toLowerCase()] = item.value;
                        return obj;
                }

            }
        }

        /**
         * Преобразованные данные для отображения (сувать сюда documnet.extendFields и lots.lot[i].extendFields)
         * */
        function getExtendFieldForModelFn(data) {
            var result = [];
            if(!data){
            	return result;
            }
            if(data.noticeExtendField)
                angular.forEach(data.noticeExtendField, function (item) {
                    var extendField = angular.copy(item.extendField[0]);
                    extendField.value = getValue(extendField);
                    result.push(extendField);
                });
            return result;
            function getValue(item) {
                switch (item.type){
                    case 'DATETIME':
                        return item.value.dateTime;
                    default:
                        return item.value[item.type.toLocaleLowerCase()];
                }
            }
        }

        /**
         * Получение сортированного списка групп
         * */
        /*function getExtendFieldGroupsForModelFn(data) {
            var result = [];
            if(data) {
                angular.forEach(data, function (item) {
                    if (!containGroup(result, item)) {
                        //var field = angular.copy(item.extendField[0]);
                        result.push({
                            tabOrdinal: item.tabOrdinal,
                            sectionOrdinal: item.sectionOrdinal,
                            sectionName: item.sectionName
                        });
                    }
                });
                result = result.sort(ordinal);
                function ordinal(a, b) {
                    if (a.tabOrdinal && b.tabOrdinal && a.tabOrdinal!=null && b.tabOrdinal!=null) {
                        if (a.tabOrdinal === b.tabOrdinal) {
                            return a.sectionOrdinal < b.sectionOrdinal;
                        } else
                            return a.tabOrdinal < b.tabOrdinal;
                    } else if (a.sectionOrdinal && b.sectionOrdinal && a.sectionOrdinal!=null && b.sectionOrdinal!=null) {
                        return a.sectionOrdinal < b.sectionOrdinal;
                    } else
                        return -1;
                }

            }
            return result;
            function containGroup(groups, item){
                var result = false;
                angular.forEach(groups, function (group) {
                    if(group.tabOrdinal===item.tabOrdinal && group.sectionOrdinal===item.sectionOrdinal) {
                        //result = true;
                        return result;
                    }
                });
                return result;
            }
        }*/

        function loadNoticeExtFieldTemplateFn(procType, customerId, purchaseMethod, scope){
            return new Promise((resolve, reject) => {
                getPurchaseMethodFn(procType, customerId).then(function (response) {
                    let noticeInfo = noticeSrv.getNoticeInfo();
                    let isReadOnly = noticeSrv.getReadOnlyForm() || !permissionSrv.hasPermission();
                    if (response.data.success) {
                        if (response.data.result.length === 1 || (response.data.result.length > 1 && purchaseMethod)) {
                            if(noticeInfo.document.purchaseMethodCode !== 'undefined') {
                                noticeInfo.document.purchaseMethodCode = response.data.result.length >1 && purchaseMethod ? (response.data.result || []).filter(pm=>pm?.eisCode===(purchaseMethod?.eisCode ? purchaseMethod?.eisCode : ''+purchaseMethod))[0].eisCode : response.data.result[0].eisCode;
                            }
                            if(noticeInfo.document.purchaseCodeName) {
                                noticeInfo.document.purchaseCodeName = !noticeInfo.document.purchaseCodeName?.eisName ? (response.data.result.filter(pm => pm?.eisName === noticeInfo.document.purchaseCodeName)||[])[0] : noticeInfo.document.purchaseCodeName;
                            } else {
                                noticeInfo.document.purchaseCodeName = response.data.result.length >1 && purchaseMethod ? (response.data.result || []).filter(pm=>pm?.eisCode===(purchaseMethod?.eisCode ? purchaseMethod?.eisCode : ''+purchaseMethod))[0] : response.data.result[0];
                            }
                            noticeSrv.setNoticeInfo(noticeInfo);
                        }
                        if (noticeSrv.isNoticeIntegrationEIS() && (response.data.result.length === 1 || (response.data.result.length > 1 && purchaseMethod))) {
                            getOtherPurchasesTemplate((noticeInfo.document.purchaseMethodCode ||
                                response.data.result.length >1) && purchaseMethod ? (response.data.result || []).filter(pm=>pm?.eisCode===(purchaseMethod?.eisCode ? purchaseMethod?.eisCode : ''+purchaseMethod))[0].eisCode : response.data.result[0].eisCode,
                                scope, noticeInfo.procedureId ? noticeInfo.document.templateVersion : noticeInfo.procedureId).then(
                                (response) => {
                                    resolve(response);
                                }, (reason) => {
                                    reject(reason);
                                }
                            );
                        } else {
                            noticeSrv.setCustomerLoading(false);
                            noticeSrv.setNoticeShowCustomerEISError(false);
                        }
                    } else {
                        let showError = true;
                        // для формы просмотра не отображаем сообщения об ошибке
                        if(isReadOnly && !noticeInfo.document.purchaseMethodCode && !noticeInfo.document.purchaseCodeName) {
                            showError = false;
                        }

                        noticeSrv.setNoticeShowCustomerEISError(showError);
                        noticeSrv.setCustomerLoading(false);
                    }
                });
            })
        }

        function getPurchaseMethodFn(procType, customerId) {
            return httpSrv.http({
                method: 'GET',
                url: '/' + procType + '/getNoticeExtFields/' + procType + '/' + customerId
            });
        }

        function getOtherPurchasesTemplate(eisCode, scope, version){
            return new Promise(function (resolve, reject) {
                propertiesService.getProperty("otherProcedures.url").then(function (host) {
                    httpSrv.requestXHR(scope, {
                        method: 'POST',
                        url: host + '/rest/other-purchases/proc/getPurchaseMethod/' + eisCode + (version ? "/" +version : '')
                    }).then(function (response) {
                        noticeSrv.setNoticeExtFields(false);
                        if (response.data.success && response.data.result) {
                            angular.forEach(response.data.result.fields.filter(f => f.type!=='LINK'), function (item) {
                                if (!noticeExtFieldTemplate[item.tabLevel]) noticeExtFieldTemplate[item.tabLevel] = [];
                                if(item.tabLevel==='NOTICE') noticeSrv.setNoticeExtFields(true);
                                noticeExtFieldTemplate[item.tabLevel].push({
                                    description: item.name,
                                    required: item.mandatory,//todo проверить
                                    type: item.type,
                                    integrCode: item.integrCode,
                                    length: item.lenght ? item.lenght : (item.type === 'STRING' ? 1000 : (item.type === 'TEXT' ? 2000 : 255)),
                                    tabOrdinal: item.tabOrdinal,
                                    tabName: item.tabName,
                                    sectionOrdinal: item.sectionOrdinal,
                                    sectionName: item.sectionName
                                });

                                noticeExtFieldTemplate[item.tabLevel].sort(function (a, b) {
                                    var aTabOrdinal = a.tabOrdinal;
                                    var bTabOrdinal = b.tabOrdinal;
                                    var aSectionOrdinal = a.sectionOrdinal;
                                    var bSectionOrdinal = b.sectionOrdinal;
                                    
                                    if(aTabOrdinal == bTabOrdinal){
                                        return (aSectionOrdinal < bSectionOrdinal) ? -1 : (aSectionOrdinal > bSectionOrdinal) ? 1 : 0;
                                    }else{
                                        return (aTabOrdinal < bTabOrdinal) ? -1 : 1;
                                    }
                                });
                            });
                            cleanExtendFields();
                            setTemplateVersionFn(response.data.result.version);
                            service.loadTemplate = true;
                            resolve(response);
                        } else {
                            if(response.data.success)
                                service.loadTemplate = true;
                            resolve(response);
                        }
                        noticeSrv.setCustomerLoading(false);
                    }, function (reason) {
                        console.log(reason);
                        reject(reason);
                    });
                });
            });
        }

        /**
         * Чистка и переименование расширенных полей (если поменялся шаблон)
         */
        function cleanExtendFields() {
            var noticeInfo = noticeSrv.getNoticeInfo();
            if(noticeInfo.document && noticeInfo.document.extendFields && noticeInfo.document.extendFields.noticeExtendField) {
                angular.forEach(noticeInfo.document.extendFields.noticeExtendField, function (item, index) {
                    if(noticeExtFieldTemplate['NOTICE'].filter(it => it.integrCode===item.extendField[0].integrCode).length===0)
                        noticeInfo.document.extendFields.noticeExtendField.splice(index, 1);
                    else
                        item.extendField[0].description =
                            noticeExtFieldTemplate['NOTICE'].filter(it => it.integrCode===item.extendField[0].integrCode)[0].description;
                });
            }
            if(noticeInfo.document && noticeInfo.document.lots && noticeInfo.document.lots.lot && noticeInfo.document.lots.lot.length>0 &&
                noticeInfo.document.lots.lot[0].extendFields &&
                noticeInfo.document.lots.lot[0].extendFields.noticeExtendField) {
                angular.forEach(noticeInfo.document.lots.lot[0].extendFields.noticeExtendField, function (item, index) {
                    if (!noticeExtFieldTemplate['LOT'].find(it => it.integrCode === item.extendField[0].integrCode)) {
                        angular.forEach(noticeInfo.document.lots.lot, function (lot) {
                            lot.extendFields.noticeExtendField.splice(index, 1);
                        });
                    } else {
                        noticeInfo.document.lots.lot.filter(lot => lot.extendFields).forEach(lot => {
                            if(lot.extendFields.noticeExtendField.find(it => it.extendField[0].integrCode === item.extendField[0].integrCode))
                                lot.extendFields.noticeExtendField.filter(it => it.extendField[0].integrCode === item.extendField[0].integrCode)[0].extendField[0].description =
                                    noticeExtFieldTemplate['LOT'].filter(it => it.integrCode === item.extendField[0].integrCode)[0].description;
                        });
                    }
                });
            }
        }

        /*function getOtherPurchasesTemplate(eisCode, scope){
            return new Promise(function (resolve, reject) {
                propertiesService.getProperty("otherProcedures.url").then(function (host) {
                    httpSrv.requestXHR(scope, {
                        method: 'POST',
                        url: host + '/rest/other-purchases/proc/getPurchaseMethod/' + eisCode
                    }).then(function (response) {
                        if (response.data.success) {
                            angular.forEach(response.data.result, function (item) {
                                if (!noticeExtFieldTemplate[item.tabLevel]) noticeExtFieldTemplate[item.tabLevel] = [];
                                if(!noticeExtFieldTemplate[item.tabLevel][item.tabOrdinal]) {
                                    noticeExtFieldTemplate[item.tabLevel][item.tabOrdinal] = {
                                        tabName: item.tabName,
                                        sections: []
                                    };
                                }
                                if(!noticeExtFieldTemplate[item.tabLevel][item.tabOrdinal].sections[item.sectionOrdinal]) {
                                    noticeExtFieldTemplate[item.tabLevel][item.tabOrdinal].sections[item.sectionOrdinal] = {
                                        sectionName: item.sectionName,
                                        items: []
                                    };
                                }

                                noticeExtFieldTemplate[item.tabLevel][item.tabOrdinal].sections[item.sectionOrdinal].items.push({
                                    description: item.name,
                                    required: item.mandatory,//todo проверить
                                    type: item.type,
                                    integrCode: item.integrCode,
                                    indexNumber: item.indexNumber,
                                    length: item.lenght ? item.lenght : (item.type === 'STRING' ? 1000 : (item.type === 'TEXT' ? 2000 : 255)),
                                    sectionOrdinal: item.sectionOrdinal,
                                    sectionName: item.sectionName,
                                    tabOrdinal: item.tabOrdinal,
                                    tabName: item.tabName
                                })
                            });
                            service.loadTemplate = true;
                            resolve(response);
                        }
                    }, function (reason) {
                        console.log(reason);
                        reject(reason);
                    });
                });
            });
        }*/
        function setLoadTemplateFn(value) {
            service.loadTemplate = value;
        }
        function isLoadTemplateFn() {
            return service.loadTemplate;
        }
        /*
        * Проставляем данные вкладки "Расширенные поля" при выходе со вкладки и при сохранении
        * */
        function setNoticeFieldFn(value) {
            var noticeInfo = noticeSrv.getNoticeInfo();
            if(!noticeInfo.document) noticeInfo.document = {};
            if(!noticeInfo.document.extendFields) noticeInfo.document.extendFields = {};
            noticeInfo.document.extendFields = value;

        }
        
        function setTemplateVersionFn(value) {
            var noticeInfo = noticeSrv.getNoticeInfo();
            if(!noticeInfo.document) noticeInfo.document = {};
            noticeInfo.document.templateVersion = value;
        }
        /*
        * Проставляем значения модели в шаблон
        * */
        function setModelToTemplateFn(template, model) {
            angular.forEach(model, function (item) {
                angular.forEach(template, function (temp) {
                    if (temp.integrCode === item.integrCode) {
                        temp.description = item.description;
                        temp.type = item.type;
                        temp.value = item.value;
                        // temp.tabOrdinal = item.tabOrdinal;
                        // temp.sectionOrdinal = item.sectionOrdinal;
                    }
                });
            });
        }

        /*
        * Проставляем значения модели в шаблон
        * */
        /*function setGroupsFn(template, group) {
            angular.forEach(template, function (item) {
                if(item.tabOrdinal===group.tabOrdinal && item.sectionOrdinal===group.sectionOrdinal) {
                    if(!group.list)
                        group.list = [];
                    group.list.push(angular.copy(item));
                }
            })
        }*/

        /**
         * Очистить поле шаблона
         */
        function clearNoticeExtFieldTemplateFn() {
            angular.forEach(noticeExtFieldTemplate, function (item, key) {
                noticeExtFieldTemplate[key] = [];
            });
        }
    }]);