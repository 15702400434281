/**
 * @author: sergeyu
 * Профиль пользователя
 */
angular.module('uetp')
    .component('userInfo', {
        templateUrl: 'app/components/profile/userInfo/userInfo.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'cryptography', 'dialogSrv',
            '$filter', 'httpSrv', 'desktopSrv', 'alertSrv', 'dictionarySrv', 'propertiesService',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, cryptography, dialogSrv, $filter,
                      httpSrv, desktopSrv, alertSrv, dictionarySrv, propertiesService) {
                var selectCert;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.options = {};
                    $scope.userInfo = {};
                    $scope.model = {};
                    $scope.certInfo = {};
                    $scope.form = {profileForm: {}};
                    $scope.authData = authSrv;
                    $scope.regNewCert = false;
                    $scope.userCertificates = {};
                    $scope.userMchds = {};
                    $scope.files;
                    $scope.userProfile = angular.copy(authSrv.getUser());
                    _initProperties();
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')['all'];
                    $scope.oldEmail = $scope.userProfile.email;
                    $scope.changedFile = false;
                    $scope.signVerifyInProcess = false;
                    $scope.isUserAgent = authSrv.isUserAgent();
                    $scope.requestsProcessingResponsible = $scope.userProfile.userProperties && $scope.userProfile.userProperties.requests_processing_responsible==="true";
                    propertiesService.getProperty("mchd.import_packages").then((value) => {
                        $scope.mchdPackageImportEnabled = value === 'true';
                        $scope.mchdInfo = 'В данном разделе возможно добавить машиночитаемую доверенность, выпущенную в едином формате, для подтверждения полномочий пользователя после добавления сертификата. ';
                        if (value === 'true') {
                            $scope.mchdInfo += '\nВыберите архив, содержащий xml-файл доверенности и файл(ы) открепленной подписи к этой доверенности.' +
                                               '\nЕсли доверенность выпущена в порядке передоверия, то архив должен дополнительно содержать файлы цепочки передоверия.';
                        } else {
                            $scope.mchdInfo += '\nДля добавления выберите вместе xml-файл доверенности и файл(ы) открепленной подписи к этой доверенности.';
                        }
                    });
                    _init()
                };
                $scope.showCertList = showCertListFn;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.registerNewCert = registerNewCertFn;
                $scope.updateUserInfo = updateUserInfoFn;
                $scope.sendConfirmationEmail = sendConfirmationEmailFn;
                $scope.rejectConfirmationEmail = rejectConfirmationEmailFn;
                $scope.downloadPowerOfAttorney = downloadPowerOfAttorneyFn;
                $scope.deletePowerOfAttorney = deletePowerOfAttorneyFn;
                $scope.uploadPowerOfAttorney = uploadPowerOfAttorneyFn;
                $scope.verifySignature = verifySignatureFn;
                $scope.switchRequestProcessing = switchRequestProcessingFn;
                $scope.uploadCert = uploadCertFn;
                $scope.onPersonalDataChanged = onPersonalDataChangedFn;
                $scope.createMchd = createMchdFn;

                function _init() {
                    if(!$scope.isUserAgent){
                        profileSrv.loadCertificates($scope.userCertificates);
                        profileSrv.loadUserMchds($scope.userMchds, authSrv.getAuth().userId);
                    }
                    if ($scope.userProfile.emailConfirm) {
                        showEmailChangeMsg();
                    }
                    propertiesService.getProperty("mchd.url").then((host) => {
                        if (!host.endsWith('/')) host += '/';
                        $scope.mchdUrl = host;
                    });
                }
                function _initProperties(){
                    if(!['operator_admin', 'organizer_admin', 'support_ro'].includes($scope.userProfile.userRole) || !$scope.userProfile.userProperties) return;
                    if($scope.userProfile.userProperties.allowed_223!=='false')
                        $scope.userProfile.userProperties.allowed_223 = 'true';
                    if($scope.userProfile.userProperties.allowed_44!=='false')
                        $scope.userProfile.userProperties.allowed_44 = 'true';
                    if($scope.userProfile.userProperties.allowed_commercial!=='false')
                        $scope.userProfile.userProperties.allowed_commercial = 'true';
                }

                /*
                 * При изменении персональных данных сбрасываем галки согласия на обработку и распространение
                 */
                function onPersonalDataChangedFn() {
                    $scope.model.consentProcessing = false;
                    $scope.model.consentDissemination = false;
                    $scope.updateConsentDates = true;
                }

                function updateUserInfoFn(model) {
                    if($scope.updateProcess) {
                        return;
                    }
                    $scope.updateProcess = true;
                    setTimeout(function () {
                        if ($scope.form && !$scope.form.profileForm.$valid) {
                            $scope.alertObjRequisitionCab = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                            $scope.updateProcess = false;
                            return;
                        }

                        const storedUser = authSrv.getUser();
                        const firstName = $scope.form.profileForm.firstName.$modelValue ?? $scope.form.profileForm.firstName,
                              lastName  = $scope.form.profileForm.lastName.$modelValue ?? $scope.form.profileForm.lastName,
                              middleName= $scope.form.profileForm.middleName.$modelValue ?? $scope.form.profileForm.middleName;
                        if(['organizer_admin', 'organizer_user', 'participant_admin','user'].includes($scope.userProfile.userRole) &&
                            $scope.form && (lastName!==storedUser.lastName || firstName!==storedUser.firstName || middleName!==storedUser.middleName)) {
                            dialogSrv.openModal('_confirmInfoExt.html', {
                                    title: "Внимание!",
                                    message: `Были внесены изменения в ФИО пользователя. После сохранения изменений нужно будет зарегистрировать сертификат этого пользователя${$scope.userProfile.typeOrg!=='fl'?' и, при необходимости, МЧД':''}. Сохранить изменения?`,
                                    buttons: [{
                                        title: "Продолжить",
                                        btnClass: "btn-success",
                                        closeValue: false,
                                        action: function () {
                                            processSave(model, true);
                                        }
                                    },{
                                        title: "Отмена",
                                        btnClass: "btn-light",
                                        closeValue: true,
                                        action: function () {
                                            $scope.updateProcess = false;
                                        }
                                    }]
                                });

                        } else
                            processSave(model);
                    }, 1000);

                    function processSave(model, clearMchd = false) {
                        if($scope.userProfile.orgRole==="organizer")
                            model.userProperties.requests_processing_responsible=$scope.requestsProcessingResponsible;
                        let reqModel = authSrv.prepareUserDataForUpdate(model);
                        if($scope.model.consentProcessing != (model.consentProcessingDate != null) || $scope.updateConsentDates){
                            reqModel.consentProcessingDate = $scope.model.consentProcessing ? new Date().getTime() : null;
                        }
                        if($scope.model.consentDissemination != (model.consentDisseminationDate != null) || $scope.updateConsentDates){
                            reqModel.consentDisseminationDate = $scope.model.consentDissemination ? new Date().getTime() : null;
                        }
                        profileSrv.updateUserInfo(reqModel, clearMchd).then(function (response) {
                            if (response.data.success) {
                                $scope.alertObjUserInfo = alertSrv.getAlertObj(response, 'Изменение произведено успешно!');
                                $scope.changedFile = false;
                                if ($scope.oldEmail != $scope.userProfile.email) {
                                    $scope.userProfile.emailConfirm = $scope.userProfile.email;
                                    showEmailChangeMsg();
                                    if (!$scope.oldEmail) $scope.oldEmail = $scope.userProfile.emailConfirm;
                                }
                                $scope.userProfile.consentProcessingDate = reqModel.consentProcessingDate;
                                $scope.userProfile.consentDisseminationDate = reqModel.consentDisseminationDate;
                                $scope.form.profileForm.$setPristine();
                                let newProfile = angular.copy($scope.userProfile);
                                newProfile.email = $scope.oldEmail;
                                authSrv.setUser(newProfile);
                                $scope.updateConsentDates = false;
                                if(clearMchd)
                                    $rootScope.$emit('mchdError', false);
                                profileSrv.loadCertificates($scope.userCertificates);
                                profileSrv.loadUserMchds($scope.userMchds, authSrv.getAuth().userId);
                                profileSrv.loadOrganizationInfo().then(userData => {
                                    if (userData.data.success) {
                                        const user = angular.copy(userData.data.result);
                                        authSrv.updateUserName(user.lastName + " " + user.firstName + (user.middleName ? (" " + user.middleName) : ""));
                                        authSrv.setUser(user);
                                        authSrv.checkProfileCert();
                                        authSrv.setReglamentSuccess(user.approvalReglament);
                                    } else {
                                        authSrv.exit();
                                    }
                                    $scope.updateProcess = false;
                                });
                            } else {
                                $scope.alertObjUserInfo = alertSrv.getAlertObj(response, 'Ошибка сохранения изменений!');
                                $scope.updateProcess = false;
                            }
                        })
                    }
                }

                function showEmailChangeMsg() {
                    var msg = (!$scope.oldEmail || $scope.oldEmail == $scope.userProfile.emailConfirm) ? 'Для подтверждения ' + $scope.userProfile.emailConfirm + ' перейдите по ссылке в письме.' : 'Для подтверждения изменения на ' + $scope.userProfile.emailConfirm + ' перейдите по ссылке в письме.'
                    $scope.alertObjUserEmail = alertSrv.getSuccessMsgWithBtn(msg);
                }

                function sendConfirmationEmailFn(model) {
                    var dataObj = {
                        param: model.email
                    };
                    profileSrv.sendConfirmationEmail(dataObj).then(function (response) {
                        if (response.data.success) {
                            dialogSrv.showDialogMsg($scope, "На указанный адрес электронной почты отправлено письмо с ссылкой для подтверждения адреса");
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }

                    })
                }

                function rejectConfirmationEmailFn() {
                    profileSrv.rejectConfirmationEmail().then(function (response) {
                        if (response.data.success) {
                            $scope.userProfile.emailConfirm = "";
                            profileSrv.loadOrganizationInfo().then(function (response) {
                                if (response.data.success) {
                                    const userData = angular.copy(response.data.result);
                                    authSrv.setUser(userData);
                                    $scope.userProfile = angular.copy(authSrv.getUser());
                                    $scope.oldEmail = $scope.userProfile.email;
                                    $scope.alertObjRejectUserEmail = alertSrv.getAlertObj(response, 'Операция отменена!');
                                    onPersonalDataChangedFn();
                                } else {

                                    $scope.alertObjUserEmail = alertSrv.getAlertObj(response);
                                }
                            })
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }

                    })
                }


                function registerNewCertFn() {
                    if (!$scope.userCertificates.certForUpload) {
                        dialogSrv.showDialogMsg($scope, "Выберите сертификат");
                        return;
                    }
                    $scope.updateOrg = false;
                    profileSrv.registerCertificate($scope.userCertificates.certForUpload).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.userCertificates.uploadCertId = response.data.result;
                            const lastCert = getDefaultCert($scope.userCertificates.storedCerts),
                                fio = lastCert?.fio || "",
                                notAfter = $filter('date')(lastCert?.notAfter, 'dd.MM.yyyy') || "";
                            if (fio) {
                                dialogSrv.showDialogPrompt($scope, "При подтверждении добавления нового сертификата, действующий до текущего момента времени сертификат " + fio + ", сроком действия до " + notAfter + ", дезактивируется.",
                                    {text: "Подтверждаю", action: addCertToUser});
                            } else {
                                addCertToUser();
                            }
                        } else {
                            if(['406_2','406_2_2'].includes(response.data.errorCode)) {
                                if(response.data.result)
                                    $scope.userCertificates.uploadCertId = response.data.result;
                                dialogSrv.showDialogPrompt($scope, response.data.message, {
                                    text: "Продолжить",
                                    action: function() {
                                        addCertToUserMchd(true, '406_2_2'!==response.data.errorCode)
                                    }
                                });
                            }
                            else
                                dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });

                    delete $scope.userCertificates.certObj;
                    delete $scope.userCertificates.certForUpload;
                    var selectCert = document.getElementById('certReg');
                    selectCert.value = "";

                    function addCertToUserMchd() {
                        addCertToUser(true);
                    }

                    function addCertToUser(updateMchdReq = false, checkNames = true) {
                        if (checkNames && $scope.userProfile.inn===$scope.certInfo.inn && !$scope.userProfile.mchdReq && $scope.certInfo.fullName && $scope.certInfo.fullName !==
                            ($scope.userProfile.fullName || ($scope.userProfile.lastName + " " + $scope.userProfile.firstName + ($scope.userProfile.middleName ? (' '+$scope.userProfile.middleName):''))) && $scope.cabType() === 'participant') {
                            setTimeout(function () {
                                dialogSrv.showDialogPrompt($scope, "Наименование организации в сертификате " + $scope.certInfo.fullName + " отличается от текущего " + ($scope.userProfile.fullName || ($scope.userProfile.lastName + " " + $scope.userProfile.firstName + ($scope.userProfile.middleName ? (' '+$scope.userProfile.middleName):''))) + ". Произвести смену наименования?",
                                    {
                                        text: "OK", action: function () {
                                            $scope.updateOrg = true;
                                        }
                                    }, updateMchdReq ? processRegisterCertMchd : processRegisterCert);
                            }, 0)//асинхронный вызов
                        } else {
                            processRegisterCert(updateMchdReq);
                        }
                    }
                    function processRegisterCertMchd() {
                        processRegisterCert(true);
                    }
                    function processRegisterCert(updateMchdReq = false) {
                        const dataObj = {
                            certId: $scope.userCertificates.uploadCertId,
                            updateOrg: $scope.updateOrg,
                            updateMchdReq: updateMchdReq,
                            validateMchd: false
                        };
                        profileSrv.addCertificateToUser(dataObj).then( (response) => {
                            if (response.data.success || (!response.data.success && response.data.errorCode==='427')) {
                                if(response.data.success)
                                    dialogSrv.showDialogMsg($scope, "Сертификат успешно зарегистрирован");
                                $scope.profileSuccess = true;
                                authSrv.setProfileSuccess(true);
                                profileSrv.loadOrganizationInfo().then(userData => {
                                    if (userData.data.success) {
                                        const user = angular.copy(userData.data.result);
                                        authSrv.setUser(user);
                                        authSrv.updateCertId(dataObj.certId);
                                        authSrv.checkProfileCert();
                                        authSrv.setReglamentSuccess(user.approvalReglament);
                                        if(!(!response.data.success && response.data.errorCode==='427')) {
                                            if(['organizer_admin', 'organizer_user', 'participant_admin', 'user'].includes(authSrv.getUser().userRole)) {
                                                profileSrv.mchdLoginCertCheck().then(checkRes => {
                                                    if (!checkRes.data.success && checkRes.data.errorCode === '427') {
                                                        sendMchdError(response);
                                                    }
                                                });
                                            }
                                        } else {
                                            sendMchdError(response);
                                            if(!response.data.success && response.data.errorCode==='427') {
                                                dialogSrv.showDialogMsgHtml($scope, response.data.errorMessage);
                                            }
                                        }
                                    } else {
                                        authSrv.exit();
                                    }

                                    function sendMchdError(response) {
                                        if ($scope.cabType() === 'participant') {
                                            $scope.userProfile.mchdReq = true;
                                        }
                                        $rootScope.$emit('mchdError', true, response.data.errorMessage);
                                    }
                                });
                                profileSrv.loadCertificates($scope.userCertificates);
                                delete $scope.userCertificates.uploadCertId;
                                $scope.userProfile.certId = response.data.result;
                            } else {
                                dialogSrv.showDialogMsg($scope, response.data.message);
                            }
                        }, (response) => {
                            console.log(response);
                        });
                    }
                }

                function getDefaultCert(certArr) {
                    if (!Array.isArray(certArr) || certArr.length === 0) return {};
                    let defaultCert;
                    certArr.forEach(function (el) {
                        if (el.description === 'default') defaultCert = el;
                    });
                    return defaultCert;
                }

                function showCertListFn() {
                    $scope.regNewCert = !$scope.regNewCert;
                    if ($scope.regNewCert) {
                        setTimeout(function () {
                            fillCertRegList();
                        }, 500)
                    }
                }

                function fillCertRegList() {
                    selectCert = document.getElementById('certReg');
                    if (!selectCert) return;
                    selectCert.removeEventListener("click", certHandler);
                    selectCert.addEventListener("click", certHandler);
                    while (selectCert.options.length > 0) {
                        selectCert.remove(0);
                    }
                    cryptography.getPlugin().then(function (plugin) {
                        fillAllCertByStore(plugin, 'certReg');
                    });
                }

                function certHandler() {

                    // Исключаем ненужную проверку текущего сертификата
                    if($scope.selectedCert?.thumbPrint === this.value) {
                        return;
                    }

                    $scope.selectedCert = null;
                    if (!this.value) {
                        $scope.userCertificates.certForUpload = null;
                        $scope.userCertificates.certObj = null;
                        return;
                    }
                    const certObj = this[this.value],
                          subjectName = certObj.subjectName;

                    $scope.selectedCert = certObj;

                    if (!subjectName) return;

                    const certValidate = {"certInBase64": certObj.base64Value, "inn": $scope.userProfile.inn, "orgId": $scope.userProfile.id};

                    profileSrv.uploadCert(certValidate).then((response) => {
                        if (response.data.success) {
                            $scope.userCertificates.certObj = certObj;
                            $scope.userCertificates.certForUpload = certValidate;
                            $scope.certInfo = response.data.result;
                        } else {
                            $scope.alertObjUserInfoCert = alertSrv.getAlertObj(response);
                        }
                    }, (resp) => {
                        console.log(resp);
                    });
                }

                function uploadCertFn(cert) {
                    var a = document.createElement("a");
                    var fName = cert.getInfo;
                    fName = fName ? (fName + ".cer") : "сертификат.cer";
                    var blob = new Blob([cert.base64Value], {type: "application/octet-stream;base64"});
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, fName);
                        setTimeout(function () {
                            window.URL.revokeObjectURL(blob)
                        }, 500);
                    } else {
                        a.href = 'data:application/octet-stream;base64,' + cert.base64Value;
                        a.download = fName;
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function () {
                            document.body.removeChild(a);
                        }, 500);
                    }
                }

                function uploadPowerOfAttorneyFn(response) {
                    if (response && response.length > 0) {
                        $scope.userProfile.powerOfAttorney = {
                            fileName: response[0].fileName,
                            id: response[0].id
                        };
                        $scope.changedFile = true;
                    }
                }

                function downloadPowerOfAttorneyFn() {
                    profileSrv.downloadUserPowerOfAttorneyAgentByFileId($scope.userProfile.powerOfAttorney.id);
                }

                function deletePowerOfAttorneyFn() {
                    $scope.userProfile.powerOfAttorney = null;
                    $scope.changedFile = true;
                }

                function verifySignatureFn() {
                    $scope.signVerifyInProcess = true;
                    profileSrv.verifyCertSignature('testText', $scope.userProfile.certId).then(function (response) {
                        var result = '';
                        if (response.data.success) {
                            result = 'Проверка подписи прошла успешно!'
                        } else {
                            result = response.data.message || response.data.errorMessage;
                        }
                        $scope.signVerifyInProcess = false;
                        dialogSrv.openModal('_dialogMessage.html',
                            {message: result}).then(function () {
                            profileSrv.loadCertificates($scope.userCertificates);
                        })

                    }, function (reason) {
                        var errorText = '';
                        if (typeof reason === 'string') {
                            errorText = "Ошибка подписи документа. " + reason;
                        } else {
                            errorText = "Ошибка подписи документа. " + reason.name + ': ' + reason.message;
                        }
                        $scope.signVerifyInProcess = false;
                        dialogSrv.openModal('_dialogMessage.html',
                            {message: errorText}).then(function () {
                            profileSrv.loadCertificates($scope.userCertificates);
                        })
                    });
                }

                function switchRequestProcessingFn(el) {
                    $scope.requestsProcessingResponsible = el.requestsProcessingResponsible;
                }

                function createMchdFn(url) {
                    propertiesService.getProperty("mchd.url").then((host) => {
                        const a = document.createElement("a");
                        a.href = host + url;
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function () {
                            document.body.removeChild(a);
                        }, 500);
                    });
                }
                $scope.onSizeMchdError = function(files, maxfilesize){
                    angular.forEach(files, function (file) {
                        var errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                            'Превышен максимально разрешенный размер файла ('
                            + (maxfilesize /1048576 ).toFixed(2) + ' Мб)!<br />' +
                            'Размер файла ' + file.name + ': ' + (file.size / 1048576).toFixed(2) + ' Мб.';
                        $scope.alertObjUserInfoMchd = alertSrv.getErrorMsg(errorMsg)
                    });
                };
                $scope.onSizeEmptyMchdError = function(files){
                    angular.forEach(files, function (file) {
                        if (file.size === 0) {
                            var errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                'Невозможно добавить пустой файл.';
                            $scope.alertObjUserInfoMchd = alertSrv.getErrorMsg(errorMsg)
                        }
                    });
                };
                $scope.onFileNameMchdError = function(files){
                    angular.forEach(files, function (file) {
                        var errorMsg = 'Импорт из файла  ' + file.name + ' невозможен.<br />' +
                            'Для импорта используйте файлы формата xml.';
                        $scope.alertObjUserInfoMchd = alertSrv.getErrorMsg(errorMsg)
                    });
                };
                $scope.mchdImport = (response) => {
                    if(response.success) {
                        if(['003', '004', '038'].includes(response.result?.code) || response.result?.attorney) {
                            // Доверенность уже добавлена
                            if(response.errorCode==='431') {
                                $scope.alertObjUserInfoMchd = alertSrv.getSuccessMsg(response.errorMessage);
                            }
                            else if (response.message) {
                                dialogSrv.openModal('_confirmInfoExt.html', {
                                    title: "Подтверждение",
                                    message: response.message,
                                    buttons: [{
                                        title: "Подтверждаю",
                                        btnClass: "btn-success",
                                        closeValue: false,
                                        action: function () {
                                            acceptMchd(response, function () {
                                                $scope.alertObjUserInfoMchd = alertSrv.getSuccessMsg('Доверенность добавлена');
                                                $rootScope.$emit('mchdError', false);
                                            });
                                        }
                                    }, {
                                        title: "Отмена",
                                        btnClass: "btn-light",
                                        closeValue: true,
                                        action: function () {
                                            $scope.alertObjUserInfoMchd = alertSrv.getErrorMsg("Добавление доверенности отменено");
                                        }
                                    }]
                                });
                            } else {
                                acceptMchd(response);
                                $scope.alertObjUserInfoMchd = alertSrv.getSuccessMsg((['003', '004', '038'].includes(response.result?.code) || (!response.result?.code && !response.result?.message)) ? 'Доверенность добавлена' : response.result?.message);
                                $rootScope.$emit('mchdError', false);
                            }
                        } else if(response.result?.code && response?.result?.message) {
                            profileSrv.loadUserMchds($scope.userMchds, authSrv.getAuth().userId);
                            $scope.alertObjUserInfoMchd = alertSrv.getErrorMsg((response?.result?.message||'').replaceAll('\n','<br/>'));
                        } else {
                            profileSrv.loadUserMchds($scope.userMchds, authSrv.getAuth().userId);
                        }
                    } else if(!response.success && response.errorMessage){
                        $scope.alertObjUserInfoMchd = alertSrv.getErrorMsg((response.errorMessage||'').replaceAll('\n','<br/>'));
                    }
                    function acceptMchd(response, callback){
                        const params = {
                            userId: authSrv.getAuth().userId,
                            mchdId: response.result?.attorney?.id,
                            issued: response.result?.attorney?.issued,
                            expired: response.result?.attorney?.expired,
                            state: response.result?.attorney?.state?.code
                        }
                        profileSrv.updateUserMchd($scope.userMchds, params).then(function success(){
                            profileSrv.loadUserMchds($scope.userMchds, authSrv.getAuth().userId);
                            if(callback) callback();
                        });
                    }

                }
                $scope.isMchdReq = () => $scope.cabType()==='participant' ? $scope.userProfile.mchdReq : authSrv.getUser().mchdReq;

                $scope.hasActiveCert = () => {
                    const certId = $scope.cabType()==='participant' ? $scope.userProfile.certId : authSrv.getUser().certId;
                    if(!certId) {
                        return false;
                    } else {
                        const lastCert = getDefaultCert($scope.userCertificates?.storedCerts);
                        return lastCert?.id === certId;
                    }
                }
                $scope.getUserInn = () => {
                    return {"agentInn": $scope.hasActiveCert() ?
                            $scope.userCertificates?.storedCerts?.find(cert=>cert.id===$scope.userProfile.certId)?.inn : undefined,
                            "principalInn": $scope.cabType()==='participant' ? $scope.userProfile.inn : authSrv.getUser().inn,
                            "userId": authSrv.getAuth().userId, "token": localStorage.getItem("key")
                    }
                }
                $scope.isMchdDisabled = () => !$scope.isMchdReq() || !$scope.hasActiveCert()

                $scope.getMchdLink = (mchd) => 
                    $scope.mchdUrl + 'full?poanum=' + mchd.mchdId + '&principal=' + ($scope.cabType() === 'participant' ? $scope.userProfile.inn : authSrv.getUser().inn) + '&agent=' + mchd.agentInn;
                $scope.enterHandler = (e) => {
                    if(e.keyCode === 13) {
                        e.preventDefault();
                    }
                }
            }]
    });

