/**
 * @author: sergeyu
 * Сервис для сохранения выбранных параметров по фильтру
 */
angular.module('uetp').service('filterService', ['$state', 'dictionarySrv', '$filter',
    function ($state, dictionarySrv, $filter) {
        var service = this;

        this.modelView = {somePlaceholder: '', formName: ''};
        /**
         * Ключ - значения.
         * Ключ формируется о типа фильтра и состояние (state). Чтоб не перехватить заполненный фильтр другой страницы
         */
        service.filterNoModifyParams = {}; // данные для модели фильтра, без переименования названия переменных под сервер. Пример ng-model="doc__id" НЕ меняется на doc.id.
        service.filterModifyParams = {}; // подготовленные данные для сервера. Пример ng-model="doc__id" меняется на doc.id

        service.activeFilter = {};
        service.search = {};

        /**
         * Получение имени модели для фильтра. Используется у контролёра
         */
        service.getOrgModelName = {
            'in': 'reciver.id',
            'out': 'sender.id',
            'system': 'reciver.id'
        };

        service.dictionarySrv = dictionarySrv;
        /**
         * Заполнение названия отркытой формы и заполнение текста в строке поиска
         * @param placeholder - текст в строке поиска
         * @param formName - название открытой формы
         */
        this.initModelView = function (placeholder, formName) {
            this.modelView.somePlaceholder = placeholder;
            this.modelView.formName = formName;
        };

        /**
         * Параметры простого поиска
         * @param searchValue - значение
         * @param typeFilter - тип фильтра
         */
        this.setSearchParam = function (searchValue, typeFilter) {
            var type = typeFilter + $state.$current.name;
            service.search[type] = searchValue;
        };
        /**
         * Параметры простого поиска с явным указанием состояния
         * @param searchValue - значения
         * @param typeFilter - тип фильтра
         * @param state - состояние
         */
        this.setStateSearchParam = function (searchValue, typeFilter, state) {
            var type = typeFilter + state;
            service.search[type] = searchValue;
        };
        /**
         * Получение значений поиска по типу фильтра
         * @returns {string}
         */
        this.getSearchParam = function (typeFilter) {
            var type = typeFilter + $state.$current.name;
            return angular.isDefined(service.search[type]) ? service.search[type] : "";
        };
        /**
         * Установка выбранных параметров в фильтре
         * (не подготовленных для сервера. Имя полей фильтра равно имени его модели) doc__id = ng-model="doc__id"
         * @param params - фильтр
         * @param typeFilter - тип фильтра
         */
        this.setFilterNoModifyParams = function (params, typeFilter) {
            var type = typeFilter + $state.$current.name;
            service.filterNoModifyParams[type] = params;
        };
        /**
         * Получение заполненного фильтра по его типу (подготовленных для отправки на сервер)
         * @param typeFilter - тип фильтра
         */
        this.getFilterNoModifyParams = function (typeFilter) {
            var type = typeFilter + $state.$current.name;
            return angular.isDefined(service.filterNoModifyParams[type]) ? service.filterNoModifyParams[type] : {}
        };
        /**
         * Установка выбранных параметров в фильтре (подготовленных для отправки на сервер)
         * @param params - фильтр
         * @param typeFilter - тип фильтра
         */
        this.setFilterParams = function (params, typeFilter) {
            var type = typeFilter + $state.$current.name;
            service.filterModifyParams[type] = params;
            service.activeFilter[type] = params && Object.keys(params).length > 0;
        };
        /**
         * Получение заполненного фильтра по его типу (подготовленных для отправки на сервер)
         * @param typeFilter - тип фильтра
         */
        this.getFilterParams = function (typeFilter) {
            var type = typeFilter + $state.$current.name;
            return angular.isDefined(service.filterModifyParams[type]) ? service.filterModifyParams[type] : {}
        };
        /**
         * Получение данных активноести фильтра по его фильтра
         * @param typeFilter - тип фильтра
         */
        this.getActiveFilter = function (typeFilter, defaultValue) {
            var type = typeFilter + $state.$current.name;
            return angular.isDefined(service.activeFilter[type]) ? service.activeFilter[type] : defaultValue === true;
        };
        /**
         * Сброс данных всех фильтров
         */
        this.resetService = function () {
            service.filterModifyParams = {};
            service.filterNoModifyParams = {};
            service.activeFilter = {};
            service.search = {};
        };
        /**
         * Сброс данных указанного фильтра
         */
        this.resetCurrent = function (typeFilter, stateName) {
            var state = stateName ? stateName : $state.$current.name;
            var type = typeFilter + state;
            service.filterModifyParams[type] = {};
            service.filterNoModifyParams[type] = {};
            service.activeFilter[type] = false;
            service.search[type] = ''
        };
        this.getMaxLengthFields = function (type) {
            var fieldsFilter = {
                filterProceduresOther: {
                    searchInput: 500
                },
                filterPurchasePlan: {
                    searchInput: 500
                }
            };
            return fieldsFilter[type]
        }

        /**
         * Модифицируем модель данный фильтра в необходимые параметры для запроса на сервер
         */
        this.prepareFilterFields = function (filter, stateParamsType) {
            /**
             * Подготовка модели значений фильтра для заполнения запроса на отправку
             * @param key - ключ подготовленный (убраны все подчеркивания и заменены на точки, если требовалось)
             * @param value - значения
             */
            var fillFormatValue = function (key, value) {
                if (value instanceof Date) {
                    filter[key] = !key.startsWith("e_") ? (!key.startsWith("s_")
                            ? $filter('date')(filter[key], 'dd.MM.yyyy HH:mm:ss')
                            : $filter('date')(filter[key], 'dd.MM.yyyy') + ' 00:00:00')
                        : $filter('date')(filter[key], 'dd.MM.yyyy') + ' 23:59:59';
                } else if (key === 'orgMessage') {
                    filter[service.getOrgModelName[stateParamsType]] = value;
                    delete filter[key];
                } else if (key === 'docTypeName' || key === 'customers.region' || key === 'regionOkatoCode' || key === 'section') {
                    if (angular.isArray(value)) {
                        var multiVal = '';
                        angular.forEach(value, function (item) {
                            if (multiVal === '') {
                                multiVal += item;
                            } else {
                                multiVal += ',' + item;
                            }
                        });
                        filter[key] = multiVal;
                    } else if (angular.isObject(value)) {
                        var multiVal = '';
                        angular.forEach(value, function (value, item) {
                            if (value == true) {
                                if (multiVal === '') {
                                    multiVal += item;
                                } else {
                                    multiVal += ',' + item;
                                }
                            }
                        });
                        filter[key] = multiVal;
                    } else
                        filter[key] = value;
                } else if (key === 'customer' || key === 'customerList' || key === 'organizer') {
                    var multiVal = '';
                    if (value[0]) {
                        angular.forEach(value, function (item) {
                            if (multiVal === '') {
                                multiVal += item.id;
                            } else {
                                multiVal += ',' + item.id;
                            }
                        });
                    } else {
                        multiVal += value.id;
                    }
                    filter[key] = multiVal;
                } else if (key === 'customerTass' || key === 'supplierTass') {
                    var multiVal = '';
                    if (value[0]) {
                        angular.forEach(value, function (item) {
                            if (multiVal === '') {
                                multiVal += (item.inn ? item.inn : item.name);
                            } else {
                                multiVal += ',' + (item.inn ? item.inn : item.name);
                            }
                        });
                    } else {
                        multiVal += (value.inn ? value.inn : value.name);
                    }
                    delete filter[key];
                    filter[key.substring(0, key.length - 4)] = multiVal;
                } else if (key === 'lots.lotItems.okpd2_code' || key === 'lots.lotItems.okved2_code' || key === 'lots.lotItems.ktru_code'
                    || key === 'items.okpd2_code' || key === 'items.ktru_code' || key === 'items.okei_code' || key === 'lots.items.okpd2Code') {
                    var multiVal = '';
                    angular.forEach(value, function (item) {
                        if (multiVal === '') {
                            multiVal += item.code;
                        } else {
                            multiVal += ',' + item.code;
                        }
                    });
                    filter[key] = multiVal;
                } else if (key === 'items.okpd2ktru_code') {
                    var multiValOkpd2 = '', multiValKtru = '';
                    angular.forEach(value, function (item) {
                        if (item.index == 'qref_okpd2') {
                            if (multiValOkpd2 === '') {
                                multiValOkpd2 += item.code;
                            } else {
                                multiValOkpd2 += ',' + item.code;
                            }
                        } else if (item.index == 'qref_krtu') {
                            if (multiValKtru === '') {
                                multiValKtru += item.code;
                            } else {
                                multiValKtru += ',' + item.code;
                            }
                        }
                    });
                    if (multiValOkpd2.length > 0) filter['items.okpd2_code'] = multiValOkpd2;
                    if (multiValKtru.length > 0) filter['items.ktru_code'] = multiValKtru;
                } else if (key === 'customer.inn') {
                    filter['customer.inn'] = value.inn;
                } else if (key === 'suppliers.ogrn') {
                    filter[key] = value.ogrn;
                    filter['suppliers.inn'] = value.inn;
                    filter['suppliers.kpp'] = value.kpp;
                } else {
                    filter[key] = value;
                }
            };
            angular.forEach(filter, function (value, key) {
                if ((value == '' || value == null) && (key !== 'smp' || value == null)) {
                    delete filter[key];
                } else {
                    // nested path, change offer__owner__id -> offer.owner.id
                    if (key.indexOf('__') >= 0) {
                        delete filter[key];
                        var newKey = key.replace(/__/g, '.');
                        fillFormatValue(newKey, value);
                    } else {
                        fillFormatValue(key, value);
                    }
                }
            });
        }
    }]);